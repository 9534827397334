export default function StaffCard({
  name,
  title,
  bio,
  image,
  instagram,
  linkedin,
}) {
  return (
    <div className="staff-card">
      <div className="staff-tag rounded">
        <div className="eyebrow s">{title}</div>
      </div>
      <h3 className="header-xs">{name}</h3>
      <div className="div-block-51">
        <img alt="" src={image} loading="lazy" className="image-3" />
      </div>
      <div className="event-card-location-container">
        <h6 className="eyebrow s">Social</h6>
        <div className="staff-card-social-icon-container">
          <a href={linkedin} className="w-inline-block" target="_blank">
            <div className="staff-card-social-icon">
              <img
                src={require("../assets/logos/linkedin-logo.png")}
                loading="lazy"
                alt=""
                className="image-9"
              />
            </div>
          </a>
          <a href={instagram} className="w-inline-block" target="_blank">
            <div className="staff-card-social-icon">
              <img
                src={require("../assets/logos/instagram-logo.png")}
                loading="lazy"
                alt=""
                className="image-9"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
