import { SendFeedback } from "../services/emailServices";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export default function Leaving() {
  async function handleSubmission(e) {
    e.preventDefault();
    SendFeedback("Membership Cancellation Reasoning", e.target[0].value);
    // window.location.href = "/";
  }

  return (
    <>
      <Navbar />
      <section
        className="white-background-section"
        style={{
          backgroundImage: `https://lottie.host/561c0b9c-79fb-4ec5-b976-58dba285b891/I1KUE6kKrN.json`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <div className="w-layout-blockcontainer container">
          <div className="center">
            <DotLottieReact
              src="https://lottie.host/2b03be1b-0f19-4b7c-a80c-35fd525f7440/9ZXXQxatgy.json"
              loop
              autoplay
            />
          </div>
          <h1 className="header-xxl center">Oh, No!</h1>
          <h2 className="header-m center">
            We are sad to see you leave us, did we do something wrong?
          </h2>
          <form
            onSubmit={(e) => {
              handleSubmission(e);
            }}
          >
            <div className="field-group">
              <label className="">Tell us what happened:</label>
              <textarea
                className="checkout-field light"
                style={{ width: 300, height: 150 }}
              ></textarea>
            </div>
            <button className="button-dense-primary-dark full-width">
              Submit
            </button>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}
