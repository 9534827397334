import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import axios from "axios";
import moment from "moment";
import Navbar from "../components/navigations";
import Footer from "../components/footer";

ReactGA.initialize("G-CVD1XQ5B0K");

export default function Checkout() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const location = useLocation();
  console.log(location.state);
  const { timeframe, title, subtitle, price, calendarText, fields } =
    location.state;
  const [userDetails, setUserDetails] = useState({});
  const [cardDetails, setCardDetails] = useState({});
  const [warning, setWarning] = useState(null);
  const searchParams = new URLSearchParams(search);

  async function handleSubmit(e) {}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar type="dark" />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container">
          <div className="vertical">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="form"
              data-wf-page-id="66f4597a34ff7685625ce5d6"
              data-wf-element-id="5dd738e7-aa57-5745-4a0a-f5575b02dd00"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <h1 className="heading-4">{title}</h1>
              <div
                id="w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a89-625ce5d6"
                className="w-layout-layout quick-stack-10 wf-layout-layout"
              >
                <div
                  id="w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a8a-625ce5d6"
                  className="w-layout-cell "
                >
                  <div className="pricing-card dark">
                    <div className="pricing-card-top">
                      <div className="header-s">{timeframe}</div>
                      <div className="header-s">{subtitle}</div>
                      <div className="display-xl">
                        {price.toLocaleString("USD", {
                          style: "currency",
                          currency: "USD",
                          trailingZeros: false,
                        })}
                      </div>

                      <div className="badge">
                        <img
                          src={require("../assets/icons/calendar-2.png")}
                          loading="lazy"
                          alt=""
                          className="tag-icon"
                        />
                        <div className="tag-text">{calendarText}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a8b-625ce5d6"
                  className="w-layout-cell masterclass-checkout-right-cell"
                >
                  <div className="form-block w-form">
                    <div className="div-block-28">
                      <div className="div-block-29">
                        <label htmlFor="name" className="field-label-2">
                          First Name
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="First-Name"
                          data-name="First Name"
                          placeholder="First Name"
                          type="text"
                          id="First-Name"
                          required={true}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              firstName: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="div-block-30">
                        <label htmlFor="" className="field-label-2">
                          Last Name
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="Last-Name"
                          data-name="Last Name"
                          placeholder="Last Name"
                          type="text"
                          id="Last-Name"
                          required={true}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              lastName: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="Email" className="field-label-2">
                        Email Address
                      </label>
                      <input
                        className="checkout-field w-input"
                        maxLength="256"
                        name="Email"
                        data-name="Email"
                        placeholder="Enter your Email Address"
                        type="email"
                        id="Email"
                        required={true}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="mobile" className="field-label-2">
                        Phone Number
                      </label>
                      <input
                        className="checkout-field w-input"
                        maxLength="256"
                        name="mobile"
                        data-name="mobile"
                        placeholder="Phone Number"
                        type="tel"
                        id="mobile"
                        required={true}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            mobile: e.target.value,
                          });
                        }}
                      />
                      <div>
                        <label htmlFor="referral" className="field-label-2">
                          Referral Source
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="referral"
                          data-name="referral"
                          placeholder="Pick One"
                          type="text"
                          id="referral"
                          required={true}
                          value={searchParams.get("affiliate")}
                          disabled={
                            searchParams.get("affiliate") ? true : false
                          }
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              referral: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-block w-form">
                <div className="div-block-28">
                  <div className="div-block-29">
                    <label htmlFor="name-3" className="field-label-2">
                      Company Name
                    </label>
                    <input
                      className="checkout-field w-input"
                      maxLength="256"
                      name="company"
                      data-name="company"
                      placeholder="Example"
                      type="text"
                      id="company"
                      required={true}
                      onChange={(e) => {
                        setUserDetails({
                          ...userDetails,
                          company: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="div-block-30">
                    <label htmlFor="" className="field-label-2">
                      Company Website
                    </label>
                    <input
                      className="checkout-field w-input"
                      maxLength="256"
                      name="website"
                      data-name="website"
                      placeholder="www.example.com"
                      type="text"
                      id="website"
                      required={true}
                      onChange={(e) => {
                        setUserDetails({
                          ...userDetails,
                          website: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="" className="field-label-2">
                    NPI Number or License Number
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="license"
                    data-name="license"
                    placeholder="NPI or License"
                    type="text"
                    id="license"
                    required={true}
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        license: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="" className="field-label-2">
                    Billing Address
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="Billing-Address"
                    data-name="Name 2Billing Address"
                    placeholder="Billing Address"
                    type="text"
                    id="Billing-Address"
                    required={true}
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        billingAddress: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="" className="field-label-2">
                    Billing City
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="Billing-City"
                    data-name="Billing City"
                    placeholder="Billing City"
                    type="text"
                    id="Billing-City"
                    required={true}
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        billingCity: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="Billing-State" className="field-label-2">
                    Billing State ( Two Digit Code )
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="Billing-State"
                    data-name="Billing State"
                    placeholder="Billing State ( FL )"
                    type="text"
                    id="Billing-State"
                    required={true}
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        billingState: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="Billing-Zip-Code" className="field-label-2">
                    Billing Zip Code
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="Billing-Zip-Code"
                    data-name="Billing Zip Code"
                    placeholder="Billing Zip Code"
                    type="text"
                    id="Billing-Zip-Code"
                    required={true}
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        billingZipCode: e.target.value,
                      });
                    }}
                  />
                </div>
                <div style={{ paddingTop: 60 }}></div>
                <div>
                  <label htmlFor="" className="field-label-2">
                    First Name on Card
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="Card-First-Name"
                    data-name="Card First Name"
                    placeholder="First Name"
                    type="text"
                    id="Card-First-Name"
                    required={true}
                    onChange={(e) => {
                      setCardDetails({
                        ...cardDetails,
                        firstName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="Card-Last-Name" className="field-label-2">
                    Last Name on Card
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="Card-Last-Name"
                    data-name="Card Last Name"
                    placeholder="Last Name"
                    type="text"
                    id="Card-Last-Name"
                    required={true}
                    onChange={(e) => {
                      setCardDetails({
                        ...cardDetails,
                        lastName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="expiration-date" className="field-label-2">
                    Card Number
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="number"
                    data-name="number"
                    placeholder="**** **** **** ****"
                    type="text"
                    id="number"
                    required={true}
                    onChange={(e) => {
                      setCardDetails({
                        ...cardDetails,
                        number: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="expiration-date" className="field-label-2">
                    Expiration Date (MMYYYY)
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="Expiration-Date"
                    data-name="Expiration Date"
                    placeholder="MMYYYY"
                    type="text"
                    id="Expiration-Date-2"
                    required={true}
                    onChange={(e) => {
                      setCardDetails({
                        ...cardDetails,
                        expiry: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="" className="field-label-2">
                    CVV
                  </label>
                  <input
                    className="checkout-field w-input"
                    maxLength="256"
                    name="CVV"
                    data-name="CVV"
                    placeholder="****"
                    type="text"
                    id="CVV"
                    required={true}
                    onChange={(e) => {
                      setCardDetails({
                        ...cardDetails,
                        cvc: e.target.value,
                      });
                    }}
                  />
                </div>
                <button
                  className="white-fill-button checkout-button"
                  type="submit"
                  id="submitButton"
                >
                  Checkout
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
