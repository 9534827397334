import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  GetCourseById,
  GetMembersCompletedClasses,
  MarkClassFinished,
} from "../services/courseServices";
import VideoPlayer from "../components/videoPlayer";
import Navbar from "../components/navigations";
import Footer from "../components/footer";
import FaqSection from "../components/faqSection";
import { Dense_button_dark } from "../components/buttons";
import QuizModal from "../components/modal";

export default function Course() {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(window.location.search);
  const classId = query.get("classId");
  const isLoggedIn = localStorage.getItem("authToken");
  const [completedClasses, setCompletedClasses] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [courseDetails, setCourseDetails] = useState({
    classes: [],
    teaserVideo: null,
    title: "",
  });
  const [classDetails, setClassDetails] = useState({
    title: "Trailer Video",
    description: "",
    poster: courseDetails.teaserVideo,
  });

  //
  //
  //
  async function getCourseDetails() {
    const courseId = query.get("_id");
    const course = await GetCourseById(courseId).catch((err) => {
      console.log("failed to get course details");
      console.log(err);
      console.log(window.location.pathname + location.search);
      const redirect = location.search;

      console.log(redirect);
      navigate("/login", {
        state: { redirect: "/course" + redirect },
      });
    });
    setCourseDetails(course);
    if (query.get("classId") != null) {
      setClassDetails(course.classes.find((cls) => cls._id === classId));
    }
    document.title = "Watching Course: " + course.title;
  }

  //
  //
  //
  async function getCompletedClasses() {
    const results = await GetMembersCompletedClasses(
      localStorage.getItem("authToken")
    ).catch(() => {
      const redirect = location.search;
      navigate("/login", {
        state: {
          redirect: redirect,
        },
      });
    });
    setCompletedClasses(results.completedClasses);
  }
  //
  //
  //
  function checkClassStatus(module) {
    let completed = false;

    if (isLoggedIn === null) {
      const redirect = location.search;

      return (
        <Dense_button_dark
          text="Login to View"
          className="w-button"
          link="/login"
          state={{
            redirect: "/course" + redirect,
          }}
        />
      );
    }

    if (user.membershipIsActive) {
      if (
        completedClasses?.some(
          (completedClass) => completedClass._id === module._id
        )
      ) {
        completed = true;
        return (
          <Dense_button_dark
            text="Completed"
            link={
              "/course?" +
              new URLSearchParams({
                _id: courseDetails._id,
                classId: module._id,
                title: courseDetails.title,
                video: module.media,
              })
            }
          />
        );
      } else {
        return (
          <Dense_button_dark
            text="View Course"
            link={
              "/course?" +
              new URLSearchParams({
                _id: courseDetails._id,
                classId: module._id,
                title: courseDetails.title,
                video: module.media,
              })
            }
          />
        );
      }
    } else {
      return (
        <Dense_button_dark
          text="Membership Required"
          className="w-button"
          link="/create-account"
          state={user}
        />
      );
    }
  }
  //
  //
  //
  async function finishedWatchingVideo() {
    console.log("finished watching video");

    if (query.get("classId") === null) {
      window.location.href = "#classes";
      return;
    }

    const userId = localStorage.getItem("authToken");
    const classId = query.get("classId");
    const courseId = query.get("_id");
    const res = await MarkClassFinished(classId, courseId, userId);

    if (res.status === "success") {
      setCompletedClasses(res.data.completedClasses);
      window.location.href = "#classes";
      localStorage.setItem("user", JSON.stringify(res.data.userInformation));

      if (res.data.allClassesCompleted) {
        setModalIsOpen(true);
      }
    }

    if (res.status === "fail") {
      alert(res.message);
    }
  }
  //
  //
  //
  useEffect(() => {
    window.location.href = "#video";
    getCourseDetails();
    if (isLoggedIn) {
      getCompletedClasses();
    }
  }, [location.search]);

  //
  //
  //
  const FAQs = [
    {
      question: "What is the refund policy?",
      answer:
        "If you are not satisfied with the course, you can request a refund within 30 days of purchase.",
    },
  ];

  return (
    <>
      <QuizModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <Navbar type="dark" />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs light underline">
            {courseDetails.isCertified
              ? "CME Certified Course"
              : "Non-CME Course"}
          </div>
          <div className="header-xxl light pad-top-m center" id="video">
            {courseDetails.title}
          </div>
          <div className="header-xxl center">{classDetails.title}</div>
          <p className="body-l pad-top-m center thin">
            {classDetails.description}
          </p>
          <div className="full-video margin-top-l w-video w-embed">
            <VideoPlayer
              props={{
                poster: classDetails?.poster,
                videoURL: query.get("video"),
              }}
              callback={finishedWatchingVideo}
            />
          </div>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs title-underline" id="classes">
            Classes
          </div>
          <div className="header-xl pad-top-l">Course Agenda</div>
          <div className="class-list pad-top-m">
            {courseDetails.classes
              .sort((a, b) => {
                return a.id > b.id ? 1 : -1;
              })
              .map((module, index) => {
                return (
                  <div className="event-card-type-3" key={index}>
                    <div className="class-row" style={{ width: "100%" }}>
                      <div className="header-s">{module.title}</div>
                      {checkClassStatus(module)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xl">Key</div>
          <div className="header-xl light">Takeaways</div>
          <p className="body-l pad-top-m">
            Licensed practitioners in Florida will have the unique opportunity
            to perform live injections under expert supervision, with the option
            to bring their own models or use those provided by the event,
            ensuring practical application of newly acquired skills.
          </p>
          <div
            id="w-node-_16e638ac-8f60-6cc4-07e2-8d2c832ac2a2-a3dbe62f"
            className="w-layout-layout full-width-stack pad-top-l wf-layout-layout"
          >
            <div
              id="w-node-_0fed9320-dc3b-913b-904f-8fed217ab4cd-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Cadaver Lab</div>
              <p className="body-m pad-top-m">
                Deepen your knowledge of facial anatomy, exploring the critical
                structures involved in facial aesthetics.
              </p>
            </div>
            <div
              id="w-node-_0fed9320-dc3b-913b-904f-8fed217ab4d4-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Injection Proficiency</div>
              <p className="body-m pad-top-m">
                Master safe, patient-focused injection techniques to achieve
                optimal outcomes.
              </p>
            </div>
            <div
              id="w-node-_0fed9320-dc3b-913b-904f-8fed217ab4db-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Tailored Treatment Planning</div>
              <p className="body-m pad-top-m">
                Learn to assess patients individually and create personalized
                treatment plans.
              </p>
            </div>
            <div
              id="w-node-_4530a525-62e4-afc7-69e6-180cce47c3d3-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Rejuvenation Techniques</div>
              <p className="body-m pad-top-m">
                Gain insights into achieving natural-looking, aesthetically
                pleasing results.
              </p>
            </div>
            <div
              id="w-node-_070794e6-4972-9052-7013-d3c2acad4519-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Sales &amp; Marketing Strategies</div>
              <p className="body-m pad-top-m">
                Learn effective strategies from top medical spas to promote
                facial aesthetic services.
              </p>
            </div>
            <div
              id="w-node-e035fde8-c57a-faf5-538b-3f3a7bbf7e10-a3dbe62f"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Networking</div>
              <p className="body-m pad-top-m">
                Build collaborative relationships with peers and connect with
                leading professionals in aesthetic medicine.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Meet the Team</div>
          <div className="header-xl pad-top-l">Speakers</div>
          <p className="body-l pad-top-m">
            Our team comprises experienced medical and business professionals
            who prioritize education, training, and patient care. Our medical
            practitioners are board-certified experts in medical aesthetics, and
            our business professionals excel at running client-focused medspas.
          </p>
          <div className="staff-card-container">
            <div className="staff-card">
              <div className="staff-tag rounded">
                <div className="eyebrow s">Plastic Surgeon</div>
              </div>
              <h3 className="header-xs">Dr. Alan Durkin, M.D., M.S.</h3>
              <div className="div-block-51">
                <img
                  alt=""
                  src="/content/images/Photo.png"
                  loading="lazy"
                  className="image-3"
                />
              </div>
              <div className="event-card-location-container">
                <h6 className="eyebrow s">Social</h6>
                <div className="staff-card-social-icon-container">
                  <a href="#" className="w-inline-block">
                    <div className="staff-card-social-icon">
                      <img
                        src="images/linkedin-logo.png"
                        loading="lazy"
                        alt=""
                        className="image-9"
                      />
                    </div>
                  </a>
                  <a href="#" className="w-inline-block">
                    <div className="staff-card-social-icon">
                      <img
                        src="images/instagram-logo.png"
                        loading="lazy"
                        alt=""
                        className="image-9"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="staff-card">
              <div className="staff-tag rounded">
                <div className="eyebrow s">Plastic Surgeon</div>
              </div>
              <h3 className="header-xs">Dr. Alan Durkin, M.D., M.S.</h3>
              <div className="div-block-51">
                <img
                  sizes="(max-width: 479px) 230px, (max-width: 991px) 268px, 330px"
                  srcSet="images/Photo-p-500.png 500w, images/Photo-p-800.png 800w, images/Photo.png 842w"
                  alt=""
                  src="images/Photo.png"
                  loading="lazy"
                  className="image-3"
                />
              </div>
              <div className="event-card-location-container">
                <h6 className="eyebrow s">Social</h6>
                <div className="staff-card-social-icon-container">
                  <a href="#" className="w-inline-block">
                    <div className="staff-card-social-icon">
                      <img
                        src="images/linkedin-logo.png"
                        loading="lazy"
                        alt=""
                        className="image-9"
                      />
                    </div>
                  </a>
                  <a href="#" className="w-inline-block">
                    <div className="staff-card-social-icon">
                      <img
                        src="images/instagram-logo.png"
                        loading="lazy"
                        alt=""
                        className="image-9"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FaqSection FAQList={FAQs} />
      <Footer />
    </>
  );
}
