export default function TestimonialsSection() {
  return (
    <section id="testimonials" className="dark-background-section">
      <div className="w-layout-blockcontainer container">
        <h1 className="header-s regular light" style={{ color: "white" }}>
          From the aesthetics community
        </h1>
        <div
          id="w-node-f1c0f900-2e27-2b42-3467-77bb84e8d770-84e8d76c"
          className="w-layout-layout three-stack pad-top-m wf-layout-layout"
        >
          <div
            id="w-node-f1c0f900-2e27-2b42-3467-77bb84e8d771-84e8d76c"
            className="w-layout-cell"
          >
            <div className="testimonial-card">
              <div className="testimonial-header-container">
                <img
                  src="https://mintandneedle.com/cdn/shop/files/MIN_M_N_Website_Photo_Squares_Holiday_Bundle_Photos_7_1600x1600.png?v=1688675626"
                  loading="lazy"
                  alt=""
                  className="testimonial-image"
                />
                <div className="testimonial-date">2023</div>
              </div>
              <p className="body-s light">
                Being able to actually touch the SMAS is something you can't
                learn. It takes it from being 2D to actual 3D. And also that
                tactile sense was awesome.
              </p>
              <div className="div-block-19">
                <div className="body-s light">Brandi</div>
                <div className="body-s light extra-thin">MINT & NEEDLE</div>
              </div>
            </div>
          </div>
          <div
            id="w-node-f1c0f900-2e27-2b42-3467-77bb84e8d77e-84e8d76c"
            className="w-layout-cell"
          >
            <div className="div-block-10"></div>
            <div className="testimonial-card">
              <div className="testimonial-header-container">
                <img
                  src={require("../assets/images/staff/dr_gary.jpg")}
                  loading="lazy"
                  alt=""
                  className="testimonial-image"
                />
                <div className="testimonial-date">2023</div>
              </div>
              <p className="body-s light">
                They take time to answer all of the questions. Any question you
                have they will spend as much time as needed. Extremely
                intelligent and efficient and willing to help.
              </p>
              <div className="div-block-19">
                <div className="body-s light">Dr.Gary Russolillo</div>
                <div className="body-s light extra-thin">Plastic Surgeon</div>
              </div>
            </div>
          </div>
          <div
            id="w-node-f1c0f900-2e27-2b42-3467-77bb84e8d78c-84e8d76c"
            className="w-layout-cell"
          >
            <div className="div-block-10"></div>
            <div className="testimonial-card">
              <div className="testimonial-header-container">
                <img
                  src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                  loading="lazy"
                  alt=""
                  className="testimonial-image"
                />
                <div className="testimonial-date">2024</div>
              </div>
              <p className="body-s light">
                I came to Medspa Network and i have had the most pleasent
                experience. I have learned so much today than i have in all my
                previous trainings.
              </p>
              <div className="div-block-19">
                <div className="body-s light">Mira</div>
                <div className="body-s light extra-thin">A.P.R.N</div>
              </div>
            </div>
          </div>
          <div
            id="w-node-f1c0f900-2e27-2b42-3467-77bb84e8d79a-84e8d76c"
            className="w-layout-cell"
          >
            <div className="testimonial-card">
              <div className="testimonial-header-container">
                <img
                  src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                  loading="lazy"
                  alt=""
                  className="testimonial-image"
                />
                <div className="testimonial-date">2024</div>
              </div>
              <p className="body-s light">
                This training has been fantastic on raising my confidence on
                both the sustainability and reproducability of the overall
                results. Im so excited to bring this to my patients.
              </p>
              <div className="div-block-19">
                <div className="body-s light"></div>
                <div className="body-s light extra-thin">Salon Owner</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
