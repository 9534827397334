import { JsonLd } from "react-schemaorg";
import { useNavigate } from "react-router-dom";

export default function ProductCard({ product, index }) {
  const company = product.image.split("/")[1];
  const file = product.image.split("/")[2];
  const fileName = file.split(".")[0];
  const fileExtension = file.split(".")[1];
  const navigate = useNavigate();

  return (
    <div
      className="product-card"
      aria-label={`Product ${index + 1}`}
      style={{ borderRadius: 8, height: "auto" }}
      onClick={() => {
        navigate("/product/" + product._id);
      }}
    >
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Product",
          image: product.image || "https://via.placeholder.com/150",
          name: product.title,
          brand: product.brand,
        }}
      />
      <img
        loading="lazy"
        src={
          "/content/product-images/" +
          company +
          "/thumbnails/" +
          fileName +
          ".webp"
        }
        alt=""
        className="partner-product-image"
        aria-label="Product Image"
        style={{
          objectFit: "contain",
          backgroundColor: "white",
          borderRadius: "8px 8px 0px 0px",
          padding: 20,
        }}
      />
      <div className="div-block-49">
        <div
          className="partner-product-title"
          style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            textAlign: "left",
            alignItems: "flex-start",
          }}
        >
          <p
            className="body-s extra-thin"
            style={{
              textAlign: "left",
            }}
          >
            {String(product.brand).substring(0, 25)}
          </p>
          <p
            className="body-s"
            style={{
              textAlign: "left",
            }}
          >
            {String(product.title).substring(0, 25)}
          </p>
        </div>
        <div className="header-xs">
          {product.price > 0
            ? Number(product.price).toLocaleString("USD", {
                style: "currency",
                currency: "USD",
              })
            : null}
        </div>
        <a
          href="#"
          className="button-dense-primary-dark"
          aria-label="Add To Cart Button"
        >
          Add To Cart
        </a>
      </div>
    </div>
  );
}
