import { Dense_button_dark, Dense_Button_Light } from "../components/buttons";
import { Link, useNavigate } from "react-router-dom";

export default function IndutryPartnershipsSection({ backgroundColor }) {
  const navigate = useNavigate();
  return (
    <section id="industry-partnerships" className={backgroundColor}>
      <div className="w-layout-blockcontainer container left-justify gap-m ">
        <h5 className="header-xs">Industry partnerships</h5>
        <div className="div-block-45">
          <h2 className="header-l thin pad-top-m">
            Transform Your Practice With Exclusive Industry Partnerships.
          </h2>
          <h2 className={"header-l thin light"}>
            Joining MedSpa Network connects you with leading industry partners,
            providing access to the best products, devices, and cutting-edge
            biomaterials.
          </h2>
        </div>
        <div className="partner-logo-bar">
          <img
            src={require("../assets/logos/Rohrer.png")}
            loading="lazy"
            alt=""
            className="partner-logo"
          />
          <img
            src={require("../assets/logos/repeatMD.png")}
            loading="lazy"
            alt=""
            className="partner-logo"
          />
          <img
            src={require("../assets/logos/renuvion.webp")}
            loading="lazy"
            alt=""
            className="partner-logo"
          />
          <img
            src={require("../assets/logos/cherry.png")}
            loading="lazy"
            alt=""
            className="partner-logo"
          />
          <img
            src={require("../assets/logos/Merz_Pharma_logo.png")}
            loading="lazy"
            alt=""
            className="partner-logo"
          />
          <img
            src={require("../assets/logos/The_SaltFacial_logo_Black.png")}
            loading="lazy"
            alt=""
            className="partner-logo"
          />
          {/* <img
            src={require("../assets/logos/logo-mtf-biologics-black.jpg")}
            loading="lazy"
            alt=""
            className="partner-logo"
          /> */}
        </div>
        <div className="full-width gap-s">
          <Dense_button_dark
            link="/industry-partners"
            text="View Partnerships"
          />
          <Dense_Button_Light link="/membership" text="Get Membership" />
        </div>
        <div className="slider-container">
          <div className="info-card">
            <h4 className="header-s regular">
              Gain Exclusive Access to Top Brands
            </h4>
            <div>
              <p className="body-s">
                Benefit from our relationships with renowned pharmaceutical and
                cosmetic companies, ensuring you use only the highest quality
                products in your treatments.
              </p>
              <div className="div-block-15">
                <div className="button-m">Read More</div>
                <Link to="/industry-partners">
                  <img
                    src={require("../assets/icons/next-1.png")}
                    loading="lazy"
                    alt=""
                    className="info-card-icon"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="info-card">
            <h4 className="header-s regular">
              Enjoy Discounts and Special Offers
            </h4>
            <div>
              <p className="body-s">
                Enjoy exclusive discounts and special offers on products and
                services from our industry partners, helping you to optimize
                your practice’s resources and investments.
              </p>
              <div className="div-block-15">
                <div className="button-m">Read More</div>
                <Link to="/membership">
                  <img
                    src={require("../assets/icons/next-1.png")}
                    loading="lazy"
                    alt=""
                    className="info-card-icon"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="info-card">
            <h4 className="header-s regular">
              Participate in Exclusive Trainings and Workshops
            </h4>
            <div>
              <p className="body-s">
                Gain insights and skills directly from top experts through
                specialized training sessions and workshops conducted by our
                industry partners.
              </p>
              <div className="div-block-15">
                <div className="button-m">Read More</div>
                <Link to="/events">
                  <img
                    src={require("../assets/icons/next-1.png")}
                    loading="lazy"
                    alt=""
                    className="info-card-icon"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="info-card">
            <h4 className="header-s regular">
              Embrace Innovative Technologies
            </h4>
            <div>
              <p className="body-s">
                Stay ahead with the latest advancements in aesthetic medicine,
                from state-of-the-art devices to revolutionary treatment
                methods, supported by our industry collaborations.
              </p>
              <div className="div-block-15">
                <div className="button-m">Read More</div>
                <Link to="/about">
                  <img
                    src={require("../assets/icons/next-1.png")}
                    loading="lazy"
                    alt=""
                    className="info-card-icon"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
