export default function Certificate({
  title,
  image,
  completionDate,
  description,
}) {
  return (
    <div className="certificate">
      <div className="header-s">{title}</div>
      <div className="center">
        <img
          src={image}
          loading="lazy"
          alt=""
          className="certificate-image"
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="cert-completion-group">
        <div className="eyebrow s">Completion Date</div>
        <div className="badge-dark">
          <div>{completionDate}</div>
        </div>
      </div>
      <p className="body-s">{description}</p>
      <div className="cert-signature-group">
        <div className="eyebrow s">Alan Durkin, M.D., M.S.</div>
        <img
          src={require("../assets/images/Durkin_signature.png")}
          loading="lazy"
          alt=""
          className="image-14"
        />
      </div>
    </div>
  );
}
