import Navbar from "../components/navigations";
import {
  Dense_button_dark,
  Dense_Button_Light,
  Medium_button_secondary_dark,
  Medium_button_secondary_light,
  Primary_button_dark,
  Primary_button_light,
} from "../components/buttons";
import Footer from "../components/footer";
import { GetEvents } from "../services/events";
import { useEffect, useLayoutEffect, useState } from "react";
import TestimonialsSection from "../components/testimonials-section";
import EventCard from "../components/eventCard";
import CookieConsent from "../components/cookie-consent";
import { useNavigate } from "react-router-dom";
import IndutryPartnershipsSection from "../components/indutry-partnerships-section";
import { datadogRum } from "@datadog/browser-rum";

export default function Home() {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  async function getHomeEvents() {
    const events = await GetEvents();
    setEvents(events);
  }

  useEffect(() => {
    getHomeEvents();
  }, []);

  function advanceSlide() {
    let slider = document.getElementById("eventContainer");
    let items = document.getElementsByClassName("event-card");
    let width = items[0].offsetWidth;

    slider.style.transition = "transform 0.5s ease-in-out";

    let currentTransform = slider.style.transform.replace(/[^-?\d.]/g, "") || 0;
    let newTransform = parseFloat(currentTransform) - width - 20;
    let maxTransform = -((items.length - 2) * (width + 20));

    if (newTransform < maxTransform) {
      newTransform = maxTransform;
    } else {
      slider.style.transform = `translateX(${newTransform}px)`;
    }
  }

  function retreatSlide() {
    let slider = document.getElementById("eventContainer");
    slider.style.transform = "translateX(0px)";
  }

  return (
    <div style={{ position: "relative" }}>
      <Navbar type="dark" />
      <section id="Hero" className="hero-section">
        <div
          data-poster-url={require("../assets/images/Hero.png")}
          data-video-urls={require("../assets/videos/homePageVideoColor.mp4")}
          data-autoplay={true}
          data-loop={true}
          data-wf-ignore={true}
          className="background-video w-background-video"
          style={{ height: 860 }}
        >
          <video
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            poster={require("../assets/images/Hero.png")}
          >
            <source
              src={require("../assets/videos/homePageVideoColor.mp4")}
              data-wf-ignore={true}
            />
          </video>
          <section className="w-layout-blockcontainer container left-justify">
            <div className="div-block-44">
              <h1 className="hero-heading light">Connect. Learn. Master.</h1>
              <h1 className="hero-heading light">
                Become an Expert in Aesthetic Medicine.
              </h1>
              <h3 className="hero-subheading hide-mobile">
                At MedSpa Network, we are dedicated to empowering aesthetic
                professionals with top-tier training programs designed to
                enhance your skills, expand your knowledge, and elevate your
                practice.
              </h3>
              <div className="hero-button-container"></div>
            </div>
            {/* <div className="button-group">
              <Dense_Button_Light link="/membership" text="Learn More" />
              <Dense_button_dark
                link="/create-account"
                text="Join us Today"
                className="hide-mobile"
              />
            </div> */}
            <div
              id="w-node-d11065e9-8e2b-9f66-4819-4f26221f1c45-05e45dd5"
              className="w-layout-layout hero-overvie hide-mobile wf-layout-layout"
            >
              <div
                id="w-node-d11065e9-8e2b-9f66-4819-4f26221f1c46-05e45dd5"
                className="w-layout-cell"
              >
                <div>
                  <h5 className="hero-overview-text">
                    Advance your skills in non-invasive cosmetic treatments
                  </h5>
                </div>
              </div>
              <div
                id="w-node-d11065e9-8e2b-9f66-4819-4f26221f1c47-05e45dd5"
                className="w-layout-cell"
              >
                <div>
                  <h5 className="hero-overview-text">
                    Stay competitive with the latest techniques and
                    certifications
                  </h5>
                </div>
              </div>
              <div
                id="w-node-_10cd6ec0-2954-e67f-0ad6-d4876cdded18-05e45dd5"
                className="w-layout-cell"
              >
                <div>
                  <h5 className="hero-overview-text">
                    Improve your practice with courses that cover advanced
                    procedures
                  </h5>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section id="Events" className="dark-background-section upcoming-events">
        <div className="w-layout-blockcontainer container">
          <button
            className="slider-button-left hide-mobile"
            onClick={() => {
              retreatSlide();
            }}
          >
            <img
              src={require("../assets/icons/back-2.png")}
              loading="lazy"
              alt=""
              className="slider-button-icon"
            />
          </button>
          <button
            className="slider-button-right"
            onClick={() => {
              advanceSlide();
            }}
          >
            <img
              src={require("../assets/icons/next.png")}
              loading="lazy"
              alt=""
              className="slider-button-icon"
            />
          </button>
          <div className="full-width space-between">
            <h1 className="header-s light" style={{ color: "white" }}>
              Upcoming Webinars
            </h1>
            <a
              href="/webinars"
              className="link-block icon-button w-inline-block"
            >
              <a
                href="/webinars"
                className="button-m light"
                style={{ color: "white" }}
              >
                See All
              </a>
            </a>
          </div>
          <div
            className="slider-container"
            id="eventContainer"
            style={{ paddingTop: 32 }}
          >
            {events
              .sort((a, b) => {
                return a.eventStartDate > b.eventStartDate ? 1 : -1;
              })
              .slice(0, 5)
              .map((event, index) => {
                return <EventCard key={index} props={event} link="" />;
              })}
          </div>
        </div>
      </section>
      <section id="How" className="light-background-section white">
        <div className="w-layout-blockcontainer container left-justify">
          <div className="paragraph-section">
            <div>
              <h5 className="paragraph-section-heading">How does it work?</h5>
              <h1 className="header-xl">Transform Your Aesthetic Career </h1>
              <h1 className="header-xl light">with Expert Guidance</h1>
              <p className="body-l pad-top-m">
                Our comprehensive courses, led by industry experts, offer
                cutting-edge techniques and practical, hands-on learning
                experiences tailored to meet the needs of all medical
                professionals, from nurses and physician associates to seasoned
                surgeons and medspa owners.
              </p>
              <div className="w-layout-blockcontainer button-group ">
                <Primary_button_dark
                  link="/online-courses"
                  text="Show Courses"
                />
                <Primary_button_light link="/webinars" text="View Webinars" />
              </div>
            </div>
          </div>
          <div
            id="w-node-_8ff770a1-9997-4c80-5c92-623e14884223-14884223"
            className="w-layout-layout three-stack wf-layout-layout"
          >
            <div
              id="w-node-_8ff770a1-9997-4c80-5c92-623e14884224-14884223"
              className="w-layout-cell cell-8"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={"/content/staff/resized/squadEdit3.webp"}
                  alt=""
                  className="paragraph-card-image large"
                />
                <h2 className="paragraph-card-heading">
                  Join a Community of Experts
                </h2>
                <p className="paragraph-card-details">
                  Learn from board-certified plastic surgeons and renowned
                  medical professionals, gaining both knowledge and a supportive
                  network of peers and mentors.
                </p>
              </div>
            </div>
            <div
              id="w-node-_8ff770a1-9997-4c80-5c92-623e1488422b-14884223"
              className="w-layout-cell cell-7"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../assets/images/introduction_to_neuromodulators.jpg")}
                  alt=""
                  className="paragraph-card-image"
                />
                <h2 className="paragraph-card-heading">
                  Stay Ahead with Cutting-Edge Techniques
                </h2>
                <p className="paragraph-card-details">
                  Master the latest non-surgical aesthetic procedures alongside
                  a community of professionals dedicated to advanced techniques
                  and patient safety.
                </p>
              </div>
            </div>
            <div
              id="w-node-_8ff770a1-9997-4c80-5c92-623e14884232-14884223"
              className="w-layout-cell cell-7"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../assets/images/Rectangle-11.png")}
                  alt=""
                  className="paragraph-card-image large"
                />
                <h2 className="paragraph-card-heading">
                  Connect with Partners &amp; Industry Leaders
                </h2>
                <p className="paragraph-card-details">
                  Leverage our partnerships with top pharmaceutical companies
                  and industry innovators to access the best products, devices,
                  and professional networks for collaboration and growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="dr-durkin" className="blue-background-section">
        <div className="w-layout-blockcontainer container left-justify">
          <div className="large-section-heading-container">
            <h1 className="header-m">Training Excellence by Dr. Alan Durkin</h1>
            <div className="durkin-section">
              <div className="durkin-section-div">
                <div className="div-block-13">
                  <div className="text-block-4">Dr. Alan Durkin</div>
                  <img
                    src={require("../assets/images/Durkin_signature.png")}
                    loading="lazy"
                    alt=""
                    className="image-7"
                  />
                </div>
                <img
                  src={require("../assets/images/Durkin_Square_1000x1000.jpg")}
                  loading="lazy"
                  alt=""
                  className="image-4"
                />
              </div>
              <div className="durkin-section-div-2">
                <p className="body-m">
                  A board-certified plastic surgeon with over 20 years of
                  experience. Dr Durkin’s innovative methods and focus on
                  patient safety ensure top-notch education. His commitment
                  extends beyond the classroom, providing ongoing mentorship and
                  fostering a community for continuous learning and growth.
                </p>
                <div
                  id="w-node-d81c9dab-203c-d8fc-2a85-5c2d13eda7b0-05e45dd5"
                  className="w-layout-layout quick-stack-2 wf-layout-layout"
                >
                  <div
                    id="w-node-d81c9dab-203c-d8fc-2a85-5c2d13eda7b1-05e45dd5"
                    className="w-layout-cell"
                  >
                    <h4 className="header-xs light">Industry Leader</h4>
                    <p className="body-s light pad-top-s">
                      Dr. Durkin has authored foundational white papers and set
                      standards in the field of aesthetic medicine, driving
                      advancements and maintaining the highest industry
                      standards.
                    </p>
                  </div>
                  <div
                    id="w-node-d81c9dab-203c-d8fc-2a85-5c2d13eda7b2-05e45dd5"
                    className="w-layout-cell"
                  >
                    <h4 className="header-xs light">Expert Educator</h4>
                    <p className="body-s light pad-top-s">
                      Focusing on hands-on learning and patient safety, he
                      ensures MedSpa Network&#x27;s training programs provide
                      the latest techniques and comprehensive knowledge for
                      aesthetic professionals.
                    </p>
                  </div>
                </div>
                <div
                  id="w-node-_6906d351-9474-9686-1e41-0d19c3f52231-05e45dd5"
                  className="w-layout-layout wf-layout-layout"
                >
                  <div
                    id="w-node-_6906d351-9474-9686-1e41-0d19c3f52232-05e45dd5"
                    className="w-layout-cell horizontal"
                  >
                    <Primary_button_light link="/about" text="Learn More" />
                    <Primary_button_dark link="/team" text="Meet the Team" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="is-it-for-me" className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify">
          <div className="paragraph-section">
            <div>
              <h5 className="header-xs title-underline">Is it for me?</h5>
              <div className="paragraph-section-paragraph">
                <div>
                  <h2 className="header-xl pad-top-l">
                    Discover if Medspa Network
                  </h2>
                  <h2 className="header-xl light">is your perfect match</h2>
                  <p className="body-l pad-top-m thin">
                    Our training programs are designed for medical professionals
                    aiming to excel in the aesthetic field. Whether you’re just
                    starting or looking to enhance your expertise, we have the
                    right course for you.
                  </p>
                </div>
                <div className="paragraph-secction-button-container"></div>
              </div>
            </div>
          </div>
          <div
            id="w-node-_55ea0730-9a19-0bbe-728d-d1cf383767b7-05e45dd5"
            className="w-layout-layout three-stack wf-layout-layout"
          >
            <div
              id="w-node-_55ea0730-9a19-0bbe-728d-d1cf383767b8-05e45dd5"
              className="w-layout-cell cell-9"
            >
              <div className="div-block-10">
                <h2 className="header-m thin">Physicians, Surgeons, Nurses</h2>
                <p className="body-m">
                  Learn from board-certified plastic surgeons and renowned
                  medical professionals, gaining both knowledge and a supportive
                  network of peers and mentors.
                </p>
                <div className="div-block-52">
                  {/* <p className="body-s overlay">Brandi Gregge</p> */}
                  <img
                    loading="lazy"
                    src={require("../assets/images/surgical_training_Medspa_Network.jpg")}
                    alt=""
                    className="paragraph-card-image"
                  />
                </div>
              </div>
            </div>
            <div
              id="w-node-_55ea0730-9a19-0bbe-728d-d1cf383767bf-05e45dd5"
              className="w-layout-cell cell-10"
            >
              <div className="div-block-10">
                <h2 className="header-m thin">Aesthetic Medicine Providers</h2>
                <p className="body-m">
                  Leverage our partnerships with top pharmaceutical companies
                  and industry innovators to access the best products, devices,
                  and professional networks for collaboration and growth.
                </p>
                <img
                  loading="lazy"
                  src={require("../assets/images/stephanie_beiber_teaching.png")}
                  alt=""
                  className="paragraph-card-image"
                />
              </div>
            </div>
            <div
              id="w-node-_55ea0730-9a19-0bbe-728d-d1cf383767c6-05e45dd5"
              className="w-layout-cell cell-11"
            >
              <div className="div-block-10">
                <h2 className="header-m thin">MedSpa Owners and Operators</h2>
                <p className="body-m">
                  Master the latest non-surgical aesthetic procedures alongside
                  a community of professionals dedicated to advanced techniques
                  and patient safety.
                </p>
                <img
                  loading="lazy"
                  src={require("../assets/images/medspa_owners.png")}
                  alt=""
                  className="paragraph-card-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="how-does-it-work" className="lightgrey-background-section">
        <div
          className="w-layout-blockcontainer container left-justify "
          style={{ gap: 32 }}
        >
          <div className="paragraph-section-right-pad">
            <h2 className="header-xl">A Network Built</h2>
            <h2 className="header-xl light">on Expertise and Support.</h2>
          </div>
          <div
            id="w-node-ed344a2a-d137-dee8-e790-6fe732b339b0-05e45dd5"
            className="w-layout-layout double-stack wf-layout-layout"
          >
            <div
              id="w-node-ed344a2a-d137-dee8-e790-6fe732b339b1-05e45dd5"
              className="w-layout-cell"
            >
              <div className="featured-item">
                <h2 className="header-xs">Hands-On Training</h2>
                <p className="body-m top-pad-s">
                  Gain practical skills through immersive, hands-on sessions
                  guided by industry leaders, ensuring you master the latest
                  techniques and best practices.
                </p>
              </div>
            </div>
            <div
              id="w-node-ed344a2a-d137-dee8-e790-6fe732b339b8-05e45dd5"
              className="w-layout-cell"
            >
              <div className="featured-item">
                <h2 className="header-xs">Ongoing Mentorship</h2>
                <p className="body-m top-pad-s">
                  Benefit from ongoing mentorship and support, fostering a
                  collaborative environment where you can grow and thrive
                  professionally.
                </p>
              </div>
            </div>
            <div
              id="w-node-ed344a2a-d137-dee8-e790-6fe732b339bf-05e45dd5"
              className="w-layout-cell"
            >
              <div className="div-block-10"></div>
            </div>
          </div>
          <div className="div-block-43">
            <Primary_button_dark link="/events" text="View Events" />
            <Primary_button_light link="/webinars" text="Show Webinars" />
          </div>
        </div>
        <div
          className="w-layout-blockcontainer container left-justify"
          style={{ marginTop: 32 }}
        >
          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="w-embed-youtubevideo full-video"
          >
            <iframe
              src="https://www.youtube.com/embed/0IJQZqrJw94?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
              frameBorder="0"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                pointerEvents: "auto",
              }}
              allow="autoPlay; encrypted-media"
              allowFullScreen=""
              title="Medspa Network training December 2023"
            ></iframe>
          </div>
        </div>
      </section>
      <IndutryPartnershipsSection
        backgroundColor={"white-background-section"}
      />
      <section id="semaglutide" className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container gap-l left-justify">
          <div className="full-width space-between">
            <h1 className="header-m">Exclusive access to High-End Products</h1>
            <a href="#" className="link-block icon-button w-inline-block">
              <a className="button-m light" href="/shop">
                See All
              </a>
              <img
                src={require("../assets/icons/next-1.png")}
                loading="lazy"
                alt=""
                className="tiny-icon"
              />
            </a>
          </div>
          <div className="slider-container">
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">GLP-1 Program</div>
              <div className="div-block-17">
                <img
                  src={
                    "/content/product-images/glp/thumbnails/tirzepatide-60mg-3ml.webp"
                  }
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                <Medium_button_secondary_light
                  text="Apply Now"
                  link="/glp-information"
                />
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">Radiesse</div>
              <div className="div-block-17">
                <img
                  src={"/content/product-images/merz/thumbnails/radiesse.webp"}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                {/* <Medium_button_secondary_light
                  text="Order Now"
                  link="/product/radiesse"
                /> */}
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">Xeomin</div>
              <div className="div-block-17">
                <img
                  src={"/content/product-images/merz/thumbnails/xeomin.webp"}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                {/* <Medium_button_secondary_light
                  text="Order Now"
                  link="/product/Xeomin"
                /> */}
              </div>
            </div>
            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">Belotero</div>
              <div className="div-block-17">
                <img
                  src={"/content/product-images/merz/thumbnails/belotero.webp"}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                {/* <Medium_button_secondary_light
                  text="Order Now"
                  link="/product/Belotero"
                /> */}
              </div>
            </div>

            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">Bellafill</div>
              <div className="div-block-17">
                <img
                  src={"/content/product-images/tiger/Bellafill.webp"}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                {/* <Medium_button_secondary_light
                  text="Order Now"
                  link="/product/Bellafill"
                /> */}
              </div>
            </div>

            <div className="product-promo-card">
              <a href="#" className="tag-s w-inline-block">
                <div>exclusive medspa access</div>
              </a>
              <div className="header-s">SkinPen</div>
              <div className="div-block-17">
                <img
                  src={"/content/product-images/crown/resized/skinpen.webp"}
                  loading="lazy"
                  alt=""
                  className="image-5"
                />
              </div>
              <div className="div-block-60">
                <p className="body-s">
                  Exclusive access to FDA-approved generic Semaglutide, sourced
                  directly from the manufacturer. This high-quality alternative
                  has the same safety profile and proven efficacy for weight
                  loss as brand-name products, which are in limited supply.
                </p>
                {/* <Medium_button_secondary_light
                  text="Order Now"
                  link="/product/SkinPen"
                /> */}
              </div>
            </div>
            <div className="product-promo-card">
              <div className="header-s">See All</div>
              <div className="div-block-60">
                <p className="body-s">
                  Find the rest of our products in our shop. We offer a wide
                  range of products to help you grow your practice.
                </p>
                <Medium_button_secondary_light text="See All" link="/shop" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="get-started" className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify">
          <h5 className="header-xs light underline">Get started today</h5>
          <div className="paragraph-section-paragraph">
            <div className="div-block-46">
              <h2 className="header-l thin pad-top-m">
                Get Your Membership And Take the Next Step in Your Aesthetic
                Career.
              </h2>
              <h2 className="header-l thin light">
                Stay Ahead in the Fast-Evolving Field of Aesthetic Medicine,
                Ensuring you Have Skills, Knowledge, and Connections Needed to
                Excel.
              </h2>
            </div>
            <div className="button-group">
              <Dense_Button_Light
                link="/create-account"
                text="Get Membership"
              />
            </div>
          </div>
          {/* <div
            id="w-node-_49c61dff-c8c7-41d2-4770-b4dfc18fc3f5-05e45dd5"
            className="w-layout-layout full-width-stack wf-layout-layout"
          >
            <div
              id="w-node-_49c61dff-c8c7-41d2-4770-b4dfc18fc3f6-05e45dd5"
              className="w-layout-cell"
            >
              <h3 className="accordion-heading">Enroll and Learn</h3>
              <div className="div-block-18">
                <div className="accordion-selection">
                  <div className="accordion-text">Choose your own course</div>
                  <img
                    src={require("../assets/icons/plus-1.png")}
                    loading="lazy"
                    alt=""
                    className="accordion-icon"
                  />
                </div>
                <div className="accordion-selection">
                  <div className="accordion-text">Choose your own course</div>
                  <img
                    src={require("../assets/icons/plus-1.png")}
                    loading="lazy"
                    alt=""
                    className="accordion-icon"
                  />
                </div>
              </div>
            </div>
            <div
              id="w-node-_49c61dff-c8c7-41d2-4770-b4dfc18fc3f7-05e45dd5"
              className="w-layout-cell"
            >
              <h3 className="accordion-heading">Join the Community</h3>
              <div className="div-block-18">
                <div className="accordion-selection">
                  <div className="accordion-text">Choose your own course</div>
                  <img
                    src={require("../assets/icons/plus-1.png")}
                    loading="lazy"
                    alt=""
                    className="accordion-icon"
                  />
                </div>
                <div className="accordion-selection">
                  <div className="accordion-text">Choose your own course</div>
                  <img
                    src={require("../assets/icons/plus-1.png")}
                    loading="lazy"
                    alt=""
                    className="accordion-icon"
                  />
                </div>
              </div>
            </div>
            <div
              id="w-node-_61a8b9dc-a4f9-a5df-729f-742eef336817-05e45dd5"
              className="w-layout-cell"
            >
              <h3 className="accordion-heading">Achieve and grow</h3>
              <div className="div-block-18">
                <div className="accordion-selection">
                  <div className="accordion-text">Choose your own course</div>
                  <img
                    src={require("../assets/icons/plus-1.png")}
                    loading="lazy"
                    alt=""
                    className="accordion-icon"
                  />
                </div>
                <div className="accordion-selection">
                  <div className="accordion-text">Choose your own course</div>
                  <img
                    src={require("../assets/icons/plus-1.png")}
                    loading="lazy"
                    alt=""
                    className="accordion-icon"
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </div>
  );
}
