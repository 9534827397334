import { Dense_button_dark, Dense_Button_Light } from "./buttons";

export default function EventCardType2({
  title,
  date,
  location,
  link,
  index,
  id,
  type,
  style,
  state,
}) {
  let eventLink;

  switch (type) {
    case "Masterclass":
      eventLink = "/masterclass/" + id;
      break;
    case "Virtual":
      eventLink = "/webinar/" + id;
      break;
  }

  return (
    <div
      className={
        style == "light" ? "event-card-type-2 light" : "event-card-type-2"
      }
    >
      <div className="featured-event-title-bar">
        <div className="featured-event-title">
          <div className="header-s light" style={{ color: "white" }}>
            {title}
          </div>
          {index == 0 ? (
            <div className="date-badge-light general-1">
              <div>NEW</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="featured-event-details-bar">
        <div className="div-block-50">
          <div className="badge">
            <img
              alt=""
              src={require("../assets/icons/calendar-2.png")}
              loading="lazy"
              className="tag-icon"
            />
            <div className="eyebrow s light-aurora">{date}</div>
          </div>
          <div className="badge">
            <img
              alt=""
              src={require("../assets/icons/calendar-2.png")}
              loading="lazy"
              className="tag-icon"
            />
            <div className="eyebrow s light-aurora">{location}</div>
          </div>
        </div>
        <div className="button-group" style={{ padding: 0 }}>
          <Dense_Button_Light
            link={"/webinar/" + id}
            text="Learn More"
            state={state}
          />
          <Dense_Button_Light link={link} text="Register Now" state={state} />
        </div>
      </div>
    </div>
  );
}
