import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { Dense_Button_Light, Dense_button_dark } from "../components/buttons";
import TestimonialsSection from "../components/testimonials-section";

export default function SponsorshipApplication() {
  const sponsorPackages = [
    {
      name: "Platinum",
      price: 7950,
      features: [
        {
          name: "Vendor Booth",
          description: "(2) - 6ft Tables in Prime Location",
          value: true,
        },
        {
          name: "Promotional Session",
          description: "15 minute Promotional session at lunch",
          value: true,
        },
        {
          name: "Logo Placement",
          description: "Logo placement on all event materials",
          value: true,
        },
        {
          name: "Social Media Mentions",
          description: "3 posts with logo",
          value: true,
        },
        {
          name: "Attendee Workbooks / Onsite Guide",
          description: "Full page ad in the event program",
          value: true,
        },
        {
          name: "Complimentary Tickets",
          description: "6 complimentary tickets to the event",
          value: true,
        },
        {
          name: "Website Placement",
          description: "Logo placement on the MedSpa Network website",
          value: true,
        },
      ],
    },
    {
      name: "Gold",
      price: 5950,
      features: [
        {
          name: "Vendor Booth",
          description: "(1) - 6ft Tables in Prime Location",
          value: true,
        },
        {
          name: "Promotional Session",
          description: "5 minute promotional session at welcoming",
          value: true,
        },
        {
          name: "Logo Placement",
          description: "Logo placement on all event materials",
          value: true,
        },
        {
          name: "Social Media Mentions",
          description: "2 posts with logo",
          value: true,
        },
        {
          name: "Attendee Workbooks / Onsite Guide",
          description: "One half page ad in the event program",
          value: true,
        },
        {
          name: "Complimentary Tickets",
          description: "4 complimentary tickets to the event",
          value: true,
        },
        {
          name: "Website Placement",
          description: "Logo placement on the MedSpa Network website",
          value: true,
        },
      ],
    },
    {
      name: "Silver",
      price: 3950,
      features: [
        {
          name: "Vendor Booth",
          description: "(1) - 6ft Tables in Prime Location",
          value: true,
        },
        {
          name: "Promotional Session",
          description: "5 minute promotional session at welcoming",
          value: false,
        },
        {
          name: "Logo Placement",
          description: "Logo placement on all event materials",
          value: true,
        },
        {
          name: "Social Media Mentions",
          description: "1 posts with logo",
          value: true,
        },
        {
          name: "Attendee Workbooks / Onsite Guide",
          description: "Acknowledgement in the event program",
          value: true,
        },
        {
          name: "Complimentary Tickets",
          description: "2 complimentary tickets to the event",
          value: true,
        },
        {
          name: "Website Placement",
          description: "Logo placement on the MedSpa Network website",
          value: true,
        },
      ],
    },
    {
      name: "Lunch Sponsor",
      price: 750,
      features: [
        {
          name: "Table Placement",
          description:
            "Logo prominently displayed in lunch area and on all lunch tables",
          value: true,
        },
      ],
    },
    {
      name: "Cadaver Sponsor",
      price: 1000,
      features: [
        {
          name: "Faculty Pairing",
          description:
            "Faculty member to be paired with appropriate sponsor product(s)",
          value: true,
        },
        {
          name: "Student Access",
          description:
            "Company representative to be able to engage with crowd before, and after the lab",
          value: true,
        },
      ],
    },
  ];

  return (
    <>
      <Navbar />
      <section className="">
        <div
          data-poster-url="../assets/videos/Website-Hero-Video-BW-poster-00001.jpg"
          data-video-urls="../assets/videos/Website-Hero-Video-BW-transcode.mp4,../assets/videos/Website-Hero-Video-BW-transcode.webm"
          data-autoplay="true"
          data-loop="true"
          data-wf-ignore="true"
          className="background-video-masterclass w-background-video "
          style={{ height: "75vh" }}
        >
          <video
            id="b8440dfd-c60b-24b0-c557-d4aaa325d202-video"
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            data-wf-ignore="true"
            data-object-fit="cover"
            style={{ height: "75vh" }}
          >
            <source
              src={require("../assets/videos/Website-Hero-Video-BW-transcode.mp4")}
              data-wf-ignore="true"
            />
            <source
              src={require("../assets/videos/Website-Hero-Video-BW-transcode.webm")}
              data-wf-ignore="true"
            />
          </video>
          <div className="w-layout-blockcontainer container vertical-nav ">
            <div className="breadcrumb-nav"></div>
            <div className="masterclass-title" style={{ textAlign: "center" }}>
              <div className="header-xxl">Maximize Your Reach</div>
              <div className="header-m center">
                By becoming a sponsor for the upcoming MedSpa Network Advanced
                Masterclass Event.
              </div>
            </div>
            <div className="button-group">
              <a
                href="https://www.medspanetwork.com/content/downloads/February 2025 Masterclass - Sponsorship Guide.pdf"
                className="button-dense-primary-light "
                style={{ textDecoration: "none", cursor: "pointer" }}
                download="February 2025 Masterclass - Sponsorship Guide.pdf"
                target="_blank"
              >
                Download Sponsorship Guide
              </a>
              <a
                href="/masterclass/february2025"
                className="button-dense-primary-light "
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                View Event Page
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify gap-m">
          <h2 className="header-xl">
            Thank you for your interest in becoming a sponsor for the upcoming
            MedSpa Network Advanced Masterclass Event.
          </h2>
          <p className="body-m">
            We’re thrilled to bring back the MedSpa Network Advanced Masterclass
            in Injectable Techniques, a highly anticipated event returning this
            February 2025 due to popular demand!
          </p>
          <p className="body-m">
            At MedSpa Network, we are committed to delivering inclusive,
            top-tier training programs that empower healthcare professionals
            with a deep understanding of the latest techniques and technologies
            in non-surgical aesthetics. Led by renowned plastic surgeon Dr. Alan
            Durkin, M.D., M.S. of Ocean Drive Plastic Surgery, this program
            covers essential topics including facial anatomy, advanced injection
            techniques, and patient safety. Known for its Cadaver Lab and live
            hands-on training, this comprehensive course offers unparalleled
            learning opportunities.
          </p>
          <p className="body-m">
            As a sponsor of our February 2025 Advanced Masterclass Event, you’ll
            play a pivotal role in providing invaluable education to healthcare
            professionals while showcasing your brand and products to a highly
            targeted audience. Your support helps advance the skills and
            knowledge of medical practitioners in the ever-evolving field of
            aesthetic medicine.
          </p>
          <p className="body-m">
            Don’t miss the chance to be part of this impactful event and make a
            meaningful contribution to the growth and success of medical
            professionals. We deeply appreciate your support and look forward to
            collaborating with you to deliver an outstanding training
            experience.
          </p>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Packages</div>
          <div className="class-list pad-top-m" style={{ gap: 128 }}>
            {sponsorPackages.map((packageObj, index) => (
              <div className="event-card-type-2" key={index}>
                <div
                  className="featured-event-title-bar"
                  style={{ justifyContent: "space-between", width: "100%" }}
                >
                  <div className="featured-event-title">
                    <div className="header-l light" style={{ color: "white" }}>
                      {packageObj.name +
                        " " +
                        packageObj.price.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                    </div>
                  </div>
                  <Dense_Button_Light
                    text="Select Package"
                    link="/sponsor-checkout"
                    state={packageObj}
                  />
                </div>
                <div className="featured-event-details-bar">
                  <div
                    className="div-block-50 left-justify"
                    style={{
                      display: "flex",
                      gap: 32,
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    {packageObj.features.map((feature, index) => {
                      return feature.value == true ? (
                        <div key={index}>
                          <p
                            className="body-l "
                            style={{
                              textAlign: "left",
                              color: "white",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            {feature.name}
                          </p>
                          <p
                            className="body-s light"
                            style={{ textAlign: "left" }}
                          >
                            {feature.description}
                          </p>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xxl">Exhibitor Rules and Regulations</div>
          <div className="header-xl ">
            Exhibitors must adhere to the following rules and regulations:
          </div>
          <div>
            <h3 className="header-m">General</h3>
            <p>
              All matters and questions not explicitly addressed in these rules
              and regulations are subject to the sole discretion of MedSpa
              Network (MSN). The term “MSN,” as used herein, refers to the
              MedSpa Network, The Advanced Masterclass Injectable Training, its
              committees, agents, or employees responsible for the management
              and execution of the Masterclass 2025 Meeting and Exhibition.
              Exhibitors are expected to adhere to the policies outlined below
              to ensure the success of the event for all participants. Further
              details or clarifications can be obtained by contacting the MSN
              management team.
            </p>
          </div>
          <div>
            <h3 className="header-m">Application for Space</h3>
            <p>
              All applications for exhibitor space must be completed using the
              official application form included in this document. Once
              completed, applications should be submitted via email to
              tateum@medspanetwork.com. Spaces will be allocated on a
              first-come, first-served basis, subject to approval by MedSpa
              Network. Ensure all required fields are completed accurately to
              avoid delays in processing.
            </p>
          </div>
          <div>
            <h3 className="header-m">Assignment of Exhibit Space</h3>
            <p>
              Exhibit space will be assigned at the sole discretion of MedSpa
              Network (MSN). Applications from prospective exhibitors will be
              recorded in the order they are received, and MSN will assign space
              and notify exhibitors of their locations. While requests for
              specific exhibit locations can be submitted, MSN will make every
              effort to accommodate them; however, final exhibit space
              assignments are at the discretion of the organizers. In the event
              of a conflict regarding space allocation or other conditions, MSN
              reserves the right to: ● Assign space to the exhibitor. ●
              Rearrange the floor plan. ● Relocate any exhibit at any time
              before or during the exhibition period. Please note that exhibit
              space locations assigned in previous years are not guaranteed.
              Full payment is required to confirm and assign exhibit space.
            </p>
          </div>
          <div>
            <h3 className="header-m">Payments & Refunds</h3>
            <p>
              Full payment for exhibit space must be received by February 1st,
              2025. Failure to remit full payment by this date will result in
              the release and reassignment of the exhibit space. Space will only
              be confirmed and reserved upon receipt and processing of the final
              payment. Cancellation Policy: In the event of a cancellation, the
              exhibitor must notify MedSpa Network (MSN) in writing to be
              eligible for a refund. Cancellation penalties will apply, as
              outlined below: ● Cancellations made more than four (4) weeks
              before the show will be subject to a penalty. ● No refunds will be
              provided for cancellations made within four (4) weeks of the
              event.
            </p>
          </div>
          <div>
            <h3 className="header-m">
              Personnel Registration/On-Site Responsibilities
            </h3>
            <p>
              Registration Requirements: All booth personnel must pre-register
              prior to the event. On-site exhibitor badges will be available for
              pick-up and must be worn at all times. Badges grant access to the
              exhibit hall, meal functions in the exhibit area, and educational
              sessions (space permitting). Booth Staffing: Exhibitors are
              required to staff their booths at all times while the exhibit hall
              is open to attendees. Badge Policies: ● Badges are personal and
              non-transferable. ● Additional badges may be purchased for an
              appropriate fee, subject to availability. No refunds will be
              issued for extra badge purchases. Access Rules: ● Exhibitor
              personnel may not enter another exhibitor’s booth without prior
              permission. ● Entering or accessing an unmanned booth of another
              exhibitor is strictly prohibited. Adherence to these guidelines
              ensures a professional and respectful environment for all
              exhibitors and attendees
            </p>
          </div>
          <div>
            <h3 className="header-m">Unoccupied Space</h3>
            <p>
              Exhibit space that remains unoccupied one hour prior to the
              opening of the exhibit hall may be reassigned at the discretion of
              MedSpa Network (MSN) exhibit management. No refund of the rental
              fee will be issued for unoccupied space. Additionally, exhibit
              management reserves the right to: ● Set up the exhibit on behalf
              of the exhibitor. ● Remove any freight or materials from the booth
              area. Any associated costs for these actions will be billed to the
              exhibitor.
            </p>
          </div>
          <div>
            <h3 className="header-m">Care of Exhibit Space</h3>
            <p>
              Exhibitors are responsible for maintaining their assigned exhibit
              space in good order throughout the event. Responsibilities
              Include: ● Cleaning and dusting of the booth, displays, equipment,
              and materials. ● Ensuring the space remains tidy and professional
              in appearance. All cleaning and maintenance of the exhibit space
              are the sole responsibility of the exhibitor and must be performed
              at their own expense.
            </p>
          </div>
          <div>
            <h3 className="header-m">Conduct of Exhibitors</h3>
            <p>
              Exhibitors are expected to conduct themselves in an ethical and
              professional manner at all times, adhering to the established
              regulations. Key Guidelines: ● Badge Requirements: Exhibitor
              badges must be worn at all times while in the exhibit hall. ●
              Compliance: MSN reserves the right to revoke floor privileges for
              exhibitors who fail to comply with these regulations. ●
              Photography and Videography: No third party may photograph or
              videotape an exhibitor’s booth, products, staff, or visitors
              without prior permission from the authorized occupants of the
              booth. ● Age Restriction: Admission to the exhibit hall is
              restricted to individuals aged 18 years or older. These rules are
              designed to ensure a professional and secure environment for all
              exhibitors and attendees.
            </p>
          </div>
          <div>
            <h3 className="header-m">Security</h3>
            <p>
              The security of exhibits, exhibitor property, and the personal
              safety of exhibitors, their employees, agents, representatives,
              and guests is the sole responsibility of the exhibitor. Key
              Points: ● Exhibitors are responsible for securing their own
              property and ensuring the safety of their personnel. ● MedSpa
              Network (MSN) does not provide security services for individual
              exhibits or exhibitor belongings. ● Exhibitors are encouraged to
              take necessary precautions to protect their property and personnel
              during the event. This policy ensures clarity regarding security
              responsibilities within the exhibit hall.
            </p>
          </div>
          <div>
            <h3 className="header-m">Insurance and Liability</h3>
            <p>
              Exhibitors are solely responsible for any claims, liabilities,
              losses, damages, or expenses related to: ● Injuries to any person.
              ● Loss or damage to property arising from or connected to the
              exhibitor’s participation in the exhibition. Insurance
              Requirements: ● Exhibitors must obtain general liability insurance
              at their own expense to cover any potential risks associated with
              their participation. ● Insurance must provide adequate coverage
              for all activities and responsibilities tied to the exhibition.
              Compliance with Laws: ● It is the exhibitor’s responsibility to
              secure all necessary licenses and permits. ● Exhibitors must
              ensure compliance with all applicable federal, state, and local
              laws for activities conducted as part of or in association with
              the exhibition. MedSpa Network (MSN) is not liable for any injury,
              loss, or damage incurred during the exhibition. Exhibitors are
              advised to take all necessary precautions to protect their
              interests.
            </p>
          </div>
          <div>
            <h3 className="header-m">Safety and Fire Regulations</h3>
            <p>
              All exhibitors must adhere strictly to all applicable safety and
              fire laws and regulations. Compliance with these rules ensures the
              safety of all participants and the integrity of the event. Key
              Requirements: ● Flameproof Materials: All cloth decorations must
              be flameproof. ● Electrical Compliance: Wiring must conform to the
              rules of the local fire department and the conference facility. ●
              Clear Aisles and Exits: Exhibits must not obstruct aisles or fire
              exits at any time. ● Prohibited Materials: Combustible
              decorations, such as crepe paper, cardboard, or corrugated paper,
              are not allowed. ● Packing Materials: All packing containers,
              wrapping materials, and similar items must be removed from the
              exhibit floor and cannot be stored under tables or behind
              exhibits. ● Open Flames and Gas: Open flames, butane gas, and
              oxygen tanks are strictly prohibited. Exhibitors are responsible
              for ensuring their displays comply with these safety and fire
              regulations to maintain a secure environment for all attendees.
            </p>
          </div>
          <div>
            <h3 className="header-m">Hazardous/Medical Waste</h3>
            <p>
              Exhibitors are fully responsible for the proper removal and
              disposal of any materials classified as hazardous or medical
              waste. Key Responsibilities: ● Compliance with Regulations:
              Exhibitors must adhere to all applicable local ordinances and
              regulations regarding the disposal of hazardous or medical waste.
              ● Cost of Disposal: Any costs incurred for the removal of such
              materials from the exhibit facility will be the sole
              responsibility of the exhibitor. ● Improper Disposal Penalty: If
              hazardous or medical waste is not disposed of properly, MedSpa
              Network (MSN) reserves the right to fine the exhibiting company.
              By adhering to these guidelines, exhibitors contribute to a safe
              and compliant environment for all event participants.
            </p>
          </div>
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </>
  );
}
