import { useState, useEffect } from "react";
import Navbar from "../../components/navigations";
import { Dense_Button_Light } from "../../components/buttons";
import EventCardType2 from "../../components/eventCardType2";
import moment from "moment";
import Footer from "../../components/footer";
import FaqSection from "../../components/faqSection";
import IndutryPartnershipsSection from "../../components/indutry-partnerships-section";
import EventsListTag from "../../components/events-list-tag";
import { GetEvents } from "../../services/events";

export default function Events() {
  const eventType = window.location.pathname.split("/")[2];
  const [eventsList, setEventsList] = useState([]);
  const [filter, setFilter] = useState(eventType?.toLocaleLowerCase());
  const [tagsCounts, setTagsCounts] = useState({});

  async function getEventsList() {
    const events = await GetEvents();

    setEventsList(events);
    let tags = {};
    events.map((event) => {
      if (tags[event.fields.eventType]) {
        tags[event.fields.eventType] += 1;
        tags["all"] += 1;
      } else {
        tags[event.fields.eventType] = 1;
        tags["all"] = 1;
      }
    });
    setTagsCounts(tags);
  }

  useEffect(() => {
    getEventsList();
  }, []);

  const FAQs = [
    {
      question: "What do the CME courses cover?",
      answer:
        "Our CME courses cover a wide range of topics in aesthetic medicine, including injectables, laser treatments, skincare, and more. These courses are designed to help you stay up-to-date with the latest techniques and advancements in the field.",
    },
    {
      question: "Do i receieve course education materials?",
      answer:
        "Yes, all of our CME courses come with educational materials, including presentations, videos, and other resources to help you learn and retain the information. These materials are accessible online and can be reviewed at your own pace.",
    },
    {
      question: "Can i upgrade my membership?",
      answer:
        "Yes, you can upgrade your membership at any time by contacting our support team. We offer a range of membership options to suit your needs, including individual and group memberships.",
    },
    {
      question:
        "What differentiates MedSpa Network from other membership programs?",
      answer:
        "MedSpa Network is unique in that we offer a comprehensive range of resources, training, and community support to help you succeed in aesthetic medicine. Our membership program is designed to provide you with the tools and knowledge you need to excel in your career.",
    },
  ];

  return (
    <>
      <Navbar type="dark" />

      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light title-underline">Events</div>
          <div className="header-xxl pad-top-m">Join us at</div>
          <div className="header-xxl light pad-top-xs">
            Premier Aesthetic Events
          </div>
          <p className="body-l pad-top-m">
            Experience hands-on training, expert insights, and exclusive
            networking opportunities that will help you stay ahead in the field
            of aesthetic medicine.
          </p>

          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="full-video w-video w-embed margin-top-l"
          >
            <iframe
              className="embedly-embed"
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FX2rGgsqaq3A%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DX2rGgsqaq3A&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FX2rGgsqaq3A%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen=""
              title="Medspa Network training December 2023"
            ></iframe>
          </div>
        </div>
      </section>

      {eventType == "" ? (
        <section className="green-background-section">
          <div className="w-layout-blockcontainer container ">
            <div className="header-xs light center underline">Featured</div>
            <div className="header-xxl light pad-top-m">Masterclass</div>
            <div className="header-xxl">Art of Regenerative Aesthetics</div>
            <p className="body-l center pad-top-m">
              Experience two days of immersive training in Vero Beach, FL,
              focused on advanced injectables and facial anatomy through cadaver
              labs and live demonstrations.
            </p>
            <div className="featured-event">
              <img
                src="/content/images/durkin_teaching_cadaver_lab.webp"
                loading="lazy"
                alt=""
                className="featured-event-image"
              />
              <div className="featured-event-details">
                <div className="featured-event-title-bar">
                  <div className="featured-event-title">
                    <div className="header-s light" style={{ color: "white" }}>
                      Art of Regenerative Aesthetics
                    </div>
                    <div className="date-badge-light general-1">
                      <div>NEW</div>
                    </div>
                  </div>
                </div>
                <div className="featured-event-details-bar">
                  <div className="div-block-50">
                    <div className="badge">
                      <img
                        alt=""
                        src={require("../../assets/icons/calendar-2.png")}
                        loading="lazy"
                        className="tag-icon"
                      />
                      <div className="eyebrow s light-aurora">
                        21-22 FEB 2025
                      </div>
                    </div>
                    <div className="badge">
                      <img
                        alt=""
                        src={require("../../assets/icons/calendar-2.png")}
                        loading="lazy"
                        className="tag-icon"
                      />
                      <div className="eyebrow s light-aurora">
                        21-22 FEB 2025
                      </div>
                    </div>
                  </div>
                  <Dense_Button_Light
                    link="/masterclass/february2025"
                    text="Register Now"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs light underline">Calendar</div>
          <div className="header-xl pad-top-l">Upcoming Events</div>
          <div className="event-filter-bar">
            <EventsListTag
              tag={{ name: "All" }}
              tagsCounts={tagsCounts["all"]}
              filter={filter}
              setFilter={setFilter}
            />
            <EventsListTag
              tag={{ name: "Training" }}
              tagsCounts={tagsCounts["training"]}
              filter={filter}
              setFilter={setFilter}
            />
            <EventsListTag
              tag={{ name: "Masterclass" }}
              tagsCounts={tagsCounts["masterclass"]}
              filter={filter}
              setFilter={setFilter}
            />
            <EventsListTag
              tag={{ name: "Society" }}
              tagsCounts={tagsCounts["society"]}
              filter={filter}
              setFilter={setFilter}
            />
            <EventsListTag
              tag={{ name: "Virtual" }}
              tagsCounts={tagsCounts["Virtual"]}
              filter={filter}
              setFilter={setFilter}
            />
            <EventsListTag
              tag={{ name: "Industry" }}
              tagsCounts={tagsCounts["industry"]}
              filter={filter}
              setFilter={setFilter}
            />
          </div>
          <div className="events-list">
            {eventsList
              .filter((event) => {
                if (filter == "all") {
                  return true;
                } else {
                  return (
                    event.fields.eventType.toLowerCase() == filter.toLowerCase()
                  );
                }
              })
              .map((event, index) => {
                return (
                  <EventCardType2
                    key={index}
                    title={event.fields.eventTitle}
                    date={moment(event.fields.eventStartDate).format(
                      "DD MMM YYYY"
                    )}
                    location={event.fields.eventType}
                    link={"/masterclass/february2025"}
                    id={event.sys.id}
                    index={index}
                    type={event.fields.eventType}
                  />
                );
              })}

            {eventsList.filter((event) => {
              if (filter == "all") {
                return true;
              } else {
                return (
                  event.fields.eventType.toLowerCase() == filter.toLowerCase()
                );
              }
            }).length == 0 ? (
              <div className="header-l">
                We are sorry there are no events of this kind
              </div>
            ) : null}

            {eventsList.length > 5 ? (
              <div className="button m center">Load More</div>
            ) : null}
          </div>
        </div>
      </section>
      <IndutryPartnershipsSection
        backgroundColor={"lightgrey-background-section"}
      />
      <FaqSection backgroundColor={"white-background-section"} FAQList={FAQs} />
      <Footer />
    </>
  );
}
