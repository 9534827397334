import Navbar from "../components/navigations";
import Footer from "../components/footer";
import { useEffect, useState } from "react";
import { GetProductById } from "../services/products";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { useNavigate } from "react-router-dom";

export default function RohrerAffiliateProduct() {
  const [product, setProduct] = useState({});
  const navigate = useNavigate();

  const { removeFromCart, getItemQuantity, cartItems, addToCart } =
    useShoppingCart();

  async function getInitialData() {
    const _id = window.location.pathname.split("/").pop();
    const product = await GetProductById(_id);
    const quantity = getItemQuantity(_id);
    console.log(product);
    setProduct({
      ...product,
      quantity: quantity,
      company: product.image.split("/")[1],
      file: product.image.split("/")[2],
      fileName: product.image.split("/")[2].split(".")[0],
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getInitialData();
    console.log(cartItems);
  }, [cartItems]);

  return (
    <>
      <Navbar />
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container w-container">
          <div
            id="w-node-_54e7edc1-5170-ad65-a5dc-43245f933613-cb120944"
            className="w-layout-layout double-stack wf-layout-layout"
          >
            <div
              id="w-node-_54e7edc1-5170-ad65-a5dc-43245f933614-cb120944"
              className="w-layout-cell"
            >
              <img
                src={
                  "/content/product-images/" +
                  product.company +
                  "/resized/" +
                  product.fileName +
                  ".webp"
                }
                loading="lazy"
                alt=""
                className="product-image"
              />
            </div>
            <div
              id="w-node-_54e7edc1-5170-ad65-a5dc-43245f933615-cb120944"
              className="w-layout-cell cell-14"
            >
              <div className="header-l">{product.title}</div>
              <p className="body-s">{product.description}</p>
              <div className="header-s">
                {product.price?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
