import { useEffect, useState } from "react";
import Navbar from "../components/navigations";
import Footer from "../components/footer";
import { GetEventById } from "../services/events";
import { Dense_button_dark, Dense_Button_Light } from "../components/buttons";
import TestimonialsSection from "../components/testimonials-section";
import moment from "moment";

export default function WebinarDetails() {
  const [webinar, setWebinar] = useState({
    title: "",
    description: "",
    presenters: [],
    bannerImage: { fields: { file: { url: "" } } },
    shortDescriptions: [],
  });

  async function getWebinar() {
    const webinarId = window.location.pathname.split("/")[2];
    const webinar = await GetEventById(webinarId);
    setWebinar(webinar);
    console.log(webinar);
  }

  useEffect(() => {
    getWebinar();
  }, []);

  return (
    <>
      <Navbar type="dark" />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs light underline">Webinar</div>

          <img
            src={webinar.bannerImage.fields.file.url}
            loading="lazy"
            alt=""
            className="full-width pad-top-m"
            style={{ maxHeight: 600, objectFit: "cover" }}
          />
          <div
            className="webinar-details-bar"
            style={{
              padding: "32px 0px",
              justifyContent: "flex-start",
              // borderBottom: "1px solid dimgrey",
              width: "100%",
            }}
          >
            <div
              className="webinar-badges-container"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "flex-start",
                gap: 8,
              }}
            >
              <div className="badge">
                <img
                  alt=""
                  src={require("../assets/icons/calendar-2.png")}
                  loading="lazy"
                  className="tag-icon"
                />
                <div className="eyebrow s light-aurora">
                  {moment(webinar.eventStartDate).format("MMM DD")}
                </div>
              </div>
              <div className="badge">
                <img
                  alt=""
                  src={require("../assets/icons/calendar-2.png")}
                  loading="lazy"
                  className="tag-icon"
                />
                <div className="eyebrow s light-aurora">
                  {moment(webinar.eventStartDate).format("hh:mm") + " est"}
                </div>
              </div>

              <div className="badge">
                <img
                  alt=""
                  src={require("../assets/icons/location-pin-1.png")}
                  loading="lazy"
                  className="tag-icon"
                />
                <div className="eyebrow s light-aurora">ONLINE</div>
              </div>
            </div>
          </div>
          <div className="header-xxl light pad-top-m center">
            {webinar.eventTitle}
          </div>
          <div className="header-xxl center">Live Webinar</div>
          <p className="body-l pad-top-m center thin">
            {webinar.eventDescription}
          </p>
          <Dense_Button_Light
            text="Register Now"
            link={"/webinar/register?" + new URLSearchParams({ ...webinar })}
            state={{
              ...webinar,
            }}
          />
        </div>
      </section>
      <section
        className="dark-background-section"
        style={{ padding: "32px 0px" }}
      >
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs center light">WHAT YOU WILL LEARN</div>
          <div
            className="webinar-details-bar"
            style={{
              padding: "32px 0px",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <div
              className="webinar-badges-container"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "flex-start",
                gap: 8,
              }}
            >
              {webinar.shortDescriptions.map((description, index) => {
                return (
                  <div key={index} className="badge">
                    <div className="eyebrow s light-aurora">{description}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Meet the Team</div>
          <div className="header-xl pad-top-l">Speakers</div>
          <p className="body-l pad-top-m">
            Our team comprises experienced medical and business professionals
            who prioritize education, training, and patient care. Our medical
            practitioners are board-certified experts in medical aesthetics, and
            our business professionals excel at running client-focused medspas.
          </p>
          <div className="staff-card-container">
            {webinar.presenters.map((presenter, index) => {
              return (
                <div key={index} className="staff-card">
                  <div className="staff-tag rounded">
                    <div className="eyebrow s">{presenter.fields.title}</div>
                  </div>
                  <h3 className="header-xs">{presenter.fields.name}</h3>
                  <div className="div-block-51">
                    <img
                      alt=""
                      src={presenter.fields.image.fields.file.url}
                      loading="lazy"
                      className="image-3"
                    />
                  </div>
                  {/* <div className="event-card-location-container">
                    <h6 className="eyebrow s">Social</h6>
                    <div className="staff-card-social-icon-container">
                      <a href="#" className="w-inline-block">
                        <div className="staff-card-social-icon">
                          <img
                            src="/content/logos/linkedin-logo.png"
                            loading="lazy"
                            alt=""
                            className="image-9"
                          />
                        </div>
                      </a>
                      <a href="#" className="w-inline-block">
                        <div className="staff-card-social-icon">
                          <img
                            src="/content/logos/instagram.png"
                            loading="lazy"
                            alt=""
                            className="image-9"
                          />
                        </div>
                      </a>
                    </div>
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </>
  );
}
