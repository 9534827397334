import { useEffect, useState } from "react";
import { GetBlogs } from "../services/blogServices";
import Navbar from "../components/navigations";
import Footer from "../components/footer";
import moment from "moment";
import { JsonLd } from "react-schemaorg";
import { useLocation } from "react-router-dom";

export default function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const location = useLocation();

  async function getBlogs() {
    const data = await GetBlogs();
    setBlogs(data);
  }

  useEffect(() => {
    getBlogs();
    document.title = "Blogs | Medspa Network";
  }, []);

  return (
    <>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Blog",
          "@id": location.pathname,
          name: "Medspa Network Blogs and News",
          description: "Find all of the News from Medspa Network",
          about: "Aesthetics Trainings and Events",
          blogPost: blogs.map((blog) => {
            return {
              "@type": "BlogPosting",
              "@id": blog.sys.id,
              headline: blog.fields.title,
              name: blog.fields.title,
              description: blog.fields.content.content[0].content[0].value,
            };
          }),
        }}
      />
      <Navbar />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light underline">Blogs</div>
          <div className="header-xxl light pad-top-m">Find all of the</div>
          <div className="header-xxl">News from Medspa Network</div>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="staff-card-container">
            {blogs
              .sort((a, b) => {
                return new Date(a.fields.publishDate).getTime() <
                  new Date(b.fields.publishDate).getTime()
                  ? 1
                  : -1;
              })
              .map((blog, index) => {
                return (
                  <a
                    key={index}
                    className="staff-card"
                    style={{ textDecoration: "none" }}
                    href={`/article/${blog.sys.id}`}
                  >
                    <div className="staff-tag rounded">
                      <div className="eyebrow s">
                        {moment(blog.fields.publishDate).format("MMM DD YYYY")}
                      </div>
                    </div>
                    <h3 className="header-xs">{blog.fields.title}</h3>
                    <div className="div-block-51">
                      <img
                        alt=""
                        src={blog.fields.banner.fields.file.url}
                        loading="lazy"
                        className="image-3"
                      />
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
