import { Dense_button_dark } from "../components/buttons";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useShoppingCart } from "../context/ShoppingCartContext";

export default function CartThankYou() {
  const { resetCart } = useShoppingCart();
  resetCart();
  return (
    <>
      <Navbar />
      <section
        className="white-background-section"
        style={{
          backgroundImage: `https://lottie.host/561c0b9c-79fb-4ec5-b976-58dba285b891/I1KUE6kKrN.json`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <div className="w-layout-blockcontainer container">
          <div className="center">
            <DotLottieReact
              src="https://lottie.host/561c0b9c-79fb-4ec5-b976-58dba285b891/I1KUE6kKrN.json"
              loop
              autoplay
              style={{ width: "500px" }}
            />
            <h1 className="header-xxl">Woo Hoo!</h1>
            <h2 className="header-s">
              Your purchase has been received and your receipt is headed to your
              inbox. We will send the shipping details shortly.
            </h2>
          </div>
          <div className="button-group">
            <Dense_button_dark text="Home" link="/" />
            <Dense_button_dark text="Store" link="/shop" />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
