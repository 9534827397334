import { useEffect, useState } from "react";
import { GetBlogById } from "../services/blogServices";
import Navbar from "../components/navigations";
import Footer from "../components/footer";
import moment from "moment";
import { JsonLd } from "react-schemaorg";
import { useLocation } from "react-router-dom";

export default function Article() {
  const _id = window.location.pathname.split("/")[2];
  const location = useLocation();
  const [blog, setBlog] = useState();

  async function getBlogData() {
    const data = await GetBlogById(_id);
    setBlog(data);
  }

  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          "@id": location.pathname,
          headline: blog?.fields.title,
          name: blog?.fields.title,
          description: blog?.fields.content.content[0].content[0].value,
          about: "Aesthetics Trainings and Events",
          author: blog?.fields.author,
          image: {
            "@type": "ImageObject",
            "@id": blog?.fields.banner.fields.file.url,
            url: blog?.fields.banner.fields.file.url,
            height: "362",
            width: "388",
          },
        }}
      />
      <Navbar />
      <section aria-label="Article Content"></section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container ">
          <div
            key={blog?.sys.id}
            style={{ display: "flex", flexDirection: "column", gap: 8 }}
          >
            <img
              src={blog?.fields.banner.fields.file.url}
              style={{
                objectFit: "cover",
                width: "100%",
                aspectRatio: "1.6/1",
              }}
            />
            {/* <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}
              >
                {blog?.fields.additionalImages.map((image, index) => {
                  return (
                    <img
                      key={index}
                      src={image.fields.file.url}
                      alt={image.fields.title}
                      style={{ width: "30%" }}
                    />
                  );
                })}
              </div> */}
            <h2 className="header-m">{blog?.fields.title}</h2>
            <h5 className="header-xs">Author(s): {blog?.fields.author}</h5>
            <h5 className="header-xs">
              Published:{" "}
              {moment(blog?.fields.publishDate).format("MMM DD YYYY")}
            </h5>
            <div>
              {blog?.fields.content.content.map((node, index) => {
                if (node.nodeType != "embedded-asset-block") {
                  console.log(node);
                  return (
                    <p key={index} className="body-l pad-top-m">
                      {node.content.map((internal, index) => {
                        return (
                          <span
                            key={index}
                            style={{
                              fontSize: node.nodeType != "paragraph" ? 32 : 16,
                              lineHeight: 1.5,
                              width: "75ch",
                              fontWeight: internal.marks?.find(
                                (mark) => mark.type === "bold"
                              )
                                ? "bold"
                                : "normal",
                              fontStyle: internal.marks?.find(
                                (mark) => mark.type === "italic"
                              )
                                ? "italic"
                                : "normal",
                            }}
                          >
                            {internal.nodeType === "hyperlink" ? (
                              <a href={internal.data.uri}>
                                {internal.content[0].value}
                              </a>
                            ) : (
                              internal.value
                            )}
                          </span>
                        );
                      })}
                    </p>
                  );
                }
                if (node.nodeType == "embedded-asset-block") {
                  return (
                    <img
                      key={index}
                      src={node.data.target.fields.file.url}
                      alt={node.data.target.fields.title}
                      style={{ maxWidth: "40%" }}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
