import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { ShoppingCart } from "../components/shoppingCart";
import { UseLocaleStorage } from "../hooks/useLocalStorage";

const ShoppingCartContext = createContext({});

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

/**
 * Provides shopping cart context to its children components.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components that will receive the shopping cart context.
 *
 * @returns {JSX.Element} The provider component that wraps its children with shopping cart context.
 *
 * @context {Object} ShoppingCartContext - The context object containing shopping cart related functions and state.
 * @context {function} getItemQuantity - Function to get the quantity of a specific item in the cart.
 * @context {function} increaseQuantity - Function to increase the quantity of a specific item in the cart.
 * @context {function} decreaseQuantity - Function to decrease the quantity of a specific item in the cart.
 * @context {function} updateCartQuantity - Function to update the quantity of a specific item in the cart.
 * @context {function} removeFromCart - Function to remove a specific item from the cart.
 * @context {number} cartQuantity - The total quantity of items in the cart.
 * @context {function} openCart - Function to open the shopping cart.
 * @context {function} closeCart - Function to close the shopping cart.
 * @context {Array} cartItems - The array of items currently in the cart.
 * @context {function} resetCart - Function to reset the cart to an empty state.
 */
export function ShoppingCartProvider({ children }) {
  const [cartItems, setCartItems] = UseLocaleStorage(
    "MSN-WEBSITE-ShoppingCart",
    []
  );

  useEffect(() => {
    console.log(cartItems);
  }, [cartItems]);

  const getItemQuantity = useCallback(
    (_id) => {
      return cartItems.find((item) => item._id === _id)?.quantity || 0;
    },
    [cartItems]
  );

  function addToCart(product) {
    if (cartItems.length === 0) {
      setCartItems(() => {
        return [product];
      });
    } else {
      if (cartItems.find((item) => item._id === product._id)) {
        updateCartQuantity(product._id, product.quantity);
      } else {
        setCartItems((currentItems) => {
          return [...currentItems, product];
        });
      }
    }
  }

  const increaseQuantity = useCallback(
    (item) => {
      setCartItems((currentItems) => {
        if (
          currentItems.find((selectedItem) => selectedItem._id === item._id)
            ?.quantity == null
        ) {
          return [...currentItems, { ...item, quantity: 1 }];
        } else {
          return currentItems.map((selectedItem) => {
            if (selectedItem._id === item._id) {
              return { ...item, quantity: item.quantity + 1 };
            } else {
              return selectedItem;
            }
          });
        }
      });
    },
    [setCartItems]
  );

  function decreaseQuantity(item) {
    if (
      cartItems.find((selectedItem) => selectedItem._id === item._id)
        .quantity === 1
    ) {
      console.log(item);
      removeFromCart(item._id);
      return;
    } else {
      const newItems = cartItems.map((selectedItem) => {
        if (selectedItem._id === item._id) {
          return { ...item, quantity: item.quantity - 1 };
        } else {
          return selectedItem;
        }
      });
      setCartItems(newItems);
    }
  }

  function removeFromCart(id) {
    console.log(id);
    const newItems = cartItems.filter((item) => item._id !== id);
    console.log(newItems);
    setCartItems(newItems);
  }

  function updateCartQuantity(id, quantity) {
    const newCart = cartItems.map((item) => {
      if (item._id === id) {
        console.log("update quantity");
        if (quantity < 1) {
          removeFromCart(id);
          return;
        }
        return { ...item, quantity: quantity };
      } else {
        console.log("no update");
        return item;
      }
    });
    setCartItems(newCart);
  }

  function resetCart() {
    setCartItems([]);
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        addToCart,
        getItemQuantity,
        increaseQuantity,
        decreaseQuantity,
        updateCartQuantity,
        removeFromCart,
        cartItems,
        resetCart,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
