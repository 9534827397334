import { Link } from "react-router-dom";
export default function TeamMemberCard({
  name,
  credential,
  image,
  socials,
  bio,
}) {
  return (
    <div className="staff-card" style={{ minWidth: "300px" }}>
      <div className="staff-tag rounded">
        <div className="eyebrow s">{credential}</div>
      </div>
      <h3 className="header-xs">{name}</h3>
      <div className="div-block-47">
        <img alt="" src={image} loading="lazy" className="image-3" />
      </div>
      <div className="event-card-location-container">
        <h6 className="eyebrow s">Social</h6>
        <div className="staff-card-social-icon-container">
          {socials.linkedin == "#" ? null : (
            <a
              href={"https://www.linkedin.com/in/" + socials.linkedin}
              target="_blank"
              className="w-inline-block"
            >
              <div className="staff-card-social-icon">
                <img
                  loading="lazy"
                  src={require("../assets/logos/linkedin-logo.png")}
                  alt=""
                  className="image-9"
                />
              </div>
            </a>
          )}
          {socials.instagram == "#" ? null : (
            <a
              href={"https://www.instagram.com/" + socials.instagram}
              target="_blank"
              className="w-inline-block"
            >
              <div className="staff-card-social-icon">
                <img
                  loading="lazy"
                  src={require("../assets/logos/instagram-logo.png")}
                  alt=""
                  className="image-9"
                />
              </div>
            </a>
          )}
        </div>
      </div>
      <p className="body-s">{bio[0].slice(0, 175) + "..."}</p>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={`/team-spotlight/${name}`}
        state={{
          name: name,
          credential: credential,
          image: image,
          socials: socials,
          bio: bio,
        }}
      >
        Read More
      </Link>
    </div>
  );
}
