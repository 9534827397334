import Navbar from "../components/navigations";
import Footer from "../components/footer";
import { useEffect, useState } from "react";
import { GetProductById } from "../services/products";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { useNavigate } from "react-router-dom";

export default function Product() {
  const [product, setProduct] = useState({});
  const navigate = useNavigate();

  const { removeFromCart, getItemQuantity, cartItems, addToCart } =
    useShoppingCart();

  async function getInitialData() {
    const _id = window.location.pathname.split("/").pop();
    const product = await GetProductById(_id);
    const quantity = getItemQuantity(_id);
    console.log(product);
    setProduct({
      ...product,
      quantity: quantity,
      company: product.image.split("/")[1],
      file: product.image.split("/")[2],
      fileName: product.image.split("/")[2].split(".")[0],
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getInitialData();
    console.log(cartItems);
  }, [cartItems]);

  return (
    <>
      <Navbar />
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container w-container">
          <div
            id="w-node-_54e7edc1-5170-ad65-a5dc-43245f933613-cb120944"
            className="w-layout-layout double-stack wf-layout-layout"
          >
            <div
              id="w-node-_54e7edc1-5170-ad65-a5dc-43245f933614-cb120944"
              className="w-layout-cell"
            >
              <img
                src={
                  "/content/product-images/" +
                  product.company +
                  "/resized/" +
                  product.fileName +
                  ".webp"
                }
                loading="lazy"
                alt=""
                className="product-image"
              />
            </div>
            <div
              id="w-node-_54e7edc1-5170-ad65-a5dc-43245f933615-cb120944"
              className="w-layout-cell cell-14"
            >
              <div className="header-l">{product.title}</div>
              <p className="body-s">{product.description}</p>
              <div className="header-s">
                {product.price?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </div>
              <div className="w-layout-hflex flex-block-6">
                <div className="div-block-66">
                  <div className="eyebrow s">Quantity</div>
                  <div className="div-block-65">
                    <button
                      href="#"
                      className="button-2"
                      onClick={() => {
                        if (product.quantity === 0) {
                          removeFromCart(product._id);
                          return;
                        }
                        setProduct((prevState) => ({
                          ...prevState,
                          quantity: prevState.quantity - 1,
                        }));
                      }}
                    >
                      -
                    </button>
                    <div className="form-block-2 w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        data-wf-page-id="6786cd51ee97a040cb120944"
                        data-wf-element-id="55d67616-2928-8775-f891-0d239960bf2c"
                      >
                        <input
                          className="text-field-2 w-input"
                          name="name"
                          data-name="Name"
                          placeholder={product.quantity || 0}
                          type="number"
                          id="name"
                          style={{ width: "auto", maxWidth: "150px" }}
                          onChange={(event) => {
                            setProduct((prevState) => ({
                              ...prevState,
                              quantity: Number(event.target.value),
                            }));
                          }}
                        />
                      </form>
                    </div>
                    <button
                      href="#"
                      className="button-2 "
                      onClick={() => {
                        setProduct((prevState) => ({
                          ...prevState,
                          quantity: prevState.quantity + 1,
                        }));
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="div-block-63">
                  {product.quantity > 0 ? (
                    <button
                      id="add-to-cart"
                      onClick={() => {
                        addToCart(product);
                        const button = document.getElementById("add-to-cart");
                        button.innerHTML = "Added";
                        button.style.backgroundColor = "#4CAF50";
                      }}
                      value="Add to Cart"
                      className="button-dense-primary-dark "
                      style={{ fontSize: "16px" }}
                    >
                      Add to Cart
                    </button>
                  ) : null}
                  {cartItems.length > 0 ? (
                    <button
                      onClick={() => {
                        navigate("/cart");
                      }}
                      className="button-dense-primary-dark "
                      style={{ fontSize: "16px" }}
                    >
                      View Cart
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
