import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { useLayoutEffect, useState } from "react";
import axios from "axios";
import ProductCard from "../components/product-card";
import ReactPaginate from "react-paginate";
import AffiliateProductCard from "../components/affiliate-product-card";

export default function Shop() {
  const [products, setProducts] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    brand: "",
    applicationAreas: "",
    page: 0,
    productsPerPage: 12,
  });
  const [availableFilters, setAvailableFilters] = useState({
    brands: [],
    applicationAreas: [],
  });

  const getProducts = async () => {
    try {
      const response = await axios.get("/api/v2/Website/products");
      const productsData = response.data.data;
      const applicationAreas = productsData
        .map((product) => product.application_area?.split(","))
        .flat()
        .map((area) => area?.trim())
        .filter(Boolean);

      setProducts(productsData);
      setAvailableFilters({
        brands: [...new Set(productsData.map((product) => product.brand))],
        applicationAreas: [...new Set(applicationAreas)],
      });
    } catch (err) {
      console.error("Error fetching products:", err);
    }
  };

  const applyFilters = (products) => {
    return (
      products
        // .filter((product) => product.stock !== 0)
        .filter((product) => !filters.brand || product.brand === filters.brand)
        .filter(
          (product) =>
            !filters.applicationAreas ||
            product.application_area.includes(filters.applicationAreas)
        )
        .filter((product) =>
          product.title.toLowerCase().includes(filters.search.toLowerCase())
        )
    );
  };

  const handleFilterReset = (filterName) => {
    setFilters((prevState) => ({
      ...prevState,
      [filterName]: "",
    }));
    setItemOffset(0);
  };

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * filters.productsPerPage) % products.length;
    setItemOffset(newOffset);
    window.scrollTo(0, 0);
  };

  const filteredProducts = applyFilters(products);
  const endOffset = itemOffset + filters.productsPerPage;
  const currentItems = filteredProducts.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(
    filteredProducts.length / filters.productsPerPage
  );

  useLayoutEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      <Navbar />
      <section
        className="white-background-section"
        aria-label="Shop Section"
        style={{ backgroundColor: "#F4F5F7" }}
      >
        <div className="w-layout-blockcontainer container left-justify">
          <div className="header-xs title-underline">Shop</div>
          <div className="header-xl pad-top-l">MedSpa Shop</div>
          <p className="body-l thin">
            A selection of trust tools and treatments
          </p>
          <div
            className="shop-filter-container pad-top-m"
            aria-label="Shop Filters"
          >
            <div className="shop-filter">
              <div className="w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  data-wf-page-id="670eadb3483502735025e40d"
                  data-wf-element-id="95cdccd5-9a5e-547c-f447-0ed2065199fd"
                  aria-label="Search Form"
                  onSu
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label htmlFor="brand" className="field-label-2 light">
                      SEARCH
                    </label>
                    <button
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() => handleFilterReset("search")}
                    >
                      Clear
                    </button>
                  </div>
                  <input
                    className="checkout-field light w-input"
                    maxLength="256"
                    name="search"
                    data-name="search"
                    placeholder={
                      filters.search == ""
                        ? "Search by keyword"
                        : filters.search
                    }
                    type="text"
                    id="search"
                    onChange={(e) =>
                      setFilters({ ...filters, search: e.target.value })
                    }
                    aria-label="Search Input"
                  />
                </form>
              </div>
            </div>
            <div className="shop-filter">
              <div className="w-form">
                <form
                  id="wf-form-brand"
                  name="wf-form-brand"
                  data-name="brand"
                  method="get"
                  data-wf-page-id="670eadb3483502735025e40d"
                  data-wf-element-id="2d6d0290-1463-af72-a914-66b4babf63a4"
                  aria-label="Brand Filter Form"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label htmlFor="brand" className="field-label-2 light">
                      BRAND
                    </label>
                    <button
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() => {
                        handleFilterReset("brand");
                      }}
                    >
                      Clear
                    </button>
                  </div>
                  <select
                    id="brand"
                    name="brand"
                    data-name="brand"
                    className="checkout-field light dropdown w-select"
                    value={filters.brand}
                    onChange={(e) => {
                      setFilters({ ...filters, brand: e.target.value });
                    }}
                    aria-label="Brand Select"
                  >
                    <option value="" disabled>
                      Select one...
                    </option>
                    {availableFilters.brands
                      .sort((a, b) => {
                        return a > b ? 1 : -1;
                      })
                      .map((brand, index) => {
                        return (
                          <option value={brand} key={index}>
                            {brand}
                          </option>
                        );
                      })}
                  </select>
                </form>
              </div>
            </div>
            <div className="shop-filter">
              <div className="w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  data-wf-page-id="670eadb3483502735025e40d"
                  data-wf-element-id="d43a497b-0507-5fd9-9c98-dd453459d2a6"
                  aria-label="Type Filter Form"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label htmlFor="brand-3" className="field-label-2 light">
                      Application Area
                    </label>
                    <button
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() => handleFilterReset("applicationArea")}
                    >
                      Clear
                    </button>
                  </div>
                  <select
                    id="brand-2"
                    name="brand-2"
                    data-name="Brand 2"
                    className="checkout-field light dropdown w-select"
                    aria-label="Benefits Select"
                    value={filters.applicationAreas}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        applicationAreas: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled>
                      Select one...
                    </option>
                    {availableFilters.applicationAreas
                      .sort((a, b) => {
                        return a > b ? 1 : -1;
                      })
                      .map((area, index) => {
                        return (
                          <option value={area} key={index}>
                            {area}
                          </option>
                        );
                      })}
                  </select>
                </form>
              </div>
            </div>
            {/* <div className="shop-filter">
              <div className="w-form">
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  data-wf-page-id="670eadb3483502735025e40d"
                  data-wf-element-id="1dc45055-dff6-ed51-3f10-b77edbfa2c4c"
                  aria-label="Benefits Filter Form"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label htmlFor="brand-2" className="field-label-2 light">
                      BENEFITS
                    </label>
                    <button
                      onClick={() => {
                        setFilters((prevState) => ({
                          ...prevState,
                          applicationAreas: "",
                        }));
                      }}
                    >
                      Clear
                    </button>
                  </div>
                  <select
                    id="brand-2"
                    name="brand-2"
                    data-name="Brand 2"
                    className="checkout-field light dropdown w-select"
                    aria-label="Benefits Select"
                    value={filters.applicationAreas}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        applicationAreas: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled>
                      Select one...
                    </option>
                    {availableFilters.applicationAreas
                      .sort((a, b) => {
                        return a > b ? 1 : -1;
                      })
                      .map((area, index) => {
                        return (
                          <option value={area} key={index}>
                            {area}
                          </option>
                        );
                      })}
                  </select>
                </form>
              </div>
            </div> */}
          </div>
        </div>
        <div
          className="w-layout-blockcontainer container pad-top-large"
          aria-label="Products Container"
        >
          <div className="product-container" style={{ gridRowGap: 64 }}>
            {currentItems
              .sort((a, b) => {
                return a.title > b.title ? 1 : -1;
              })
              .map((product, index) => {
                if (product.affiliate === true) {
                  return (
                    <AffiliateProductCard
                      loading="lazy"
                      key={index}
                      index={index}
                      product={product}
                    />
                  );
                } else {
                  return (
                    <ProductCard
                      loading="lazy"
                      key={index}
                      index={index}
                      product={product}
                    />
                  );
                }
              })}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName="paginationContainer"
            activeClassName="paginationActive"
            breakClassName="paginationBreak"
            marginPagesDisplayed={2}
            pageLinkClassName="paginationPage"
            previousLinkClassName="paginationPrevious"
            nextLinkClassName="paginationNext"
            activeLinkClassName="ActiveLink"
          />
        </div>
      </section>
      <Footer />
    </>
  );
}
