import { useState, useEffect } from "react";
import Navbar from "../components/navigations";
import { Dense_button_dark, Dense_Button_Light } from "../components/buttons";
import EventCardType2 from "../components/eventCardType2";
import axios from "axios";
import moment from "moment";
import Footer from "../components/footer";
import FaqSection from "../components/faqSection";
import IndutryPartnershipsSection from "../components/indutry-partnerships-section";
import { GetEvents } from "../services/events";

export default function Webinars() {
  const [eventsToDisplay, setEventsToDisplay] = useState(5);
  const [eventsList, setEventsList] = useState([]);
  const [newestEvent, setNewestEvent] = useState({
    fields: {
      eventTitle: "",
      eventStartDate: "",
      bannerImage: { fields: { file: { url: "" } } },
    },
  });
  const [filter, setFilter] = useState("virtual");

  async function getEventsList() {
    const events = await GetEvents();
    console.log(events);
    const firstEvent = events.sort((a, b) => {
      if (a.fields.eventStartDate < b.fields.eventStartDate) {
        return -1;
      } else {
        return 1;
      }
    })[0];

    setNewestEvent(firstEvent);

    setEventsList(
      events
        .sort((a, b) => {
          if (a.fields.eventStartDate < b.fields.eventStartDate) {
            return -1;
          } else {
            return 1;
          }
        })
        .slice(0, eventsToDisplay)
        .filter((event) => {
          if (filter == "") {
            return true;
          } else {
            return event.fields.eventType.toLowerCase() == filter.toLowerCase();
          }
        })
        .map((event, index) => {
          return (
            <EventCardType2
              key={index}
              title={event.fields.eventTitle}
              date={moment(event.fields.eventStartDate).format("DD MMM YYYY")}
              location={event.fields.eventType}
              link={"/webinar/register?" + new URLSearchParams(event.fields)}
              text="Register Now"
              id={event.sys.id}
              index={index}
              type={event.fields.eventType}
            />
          );
        })
    );
  }

  useEffect(() => {
    getEventsList();
    document.title = "Webinars | Medspa Network";
  }, [eventsToDisplay]);

  return (
    <>
      <Navbar type="dark" />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light title-underline">Webinars</div>
          <div className="header-xxl pad-top-m">Stay Ahead With</div>
          <div className="header-xxl light pad-top-xs">Expert-led Webinars</div>
          <p className="body-l pad-top-m">
            Our webinars provide a highly focused and interactive learning
            experience, designed to deepen your knowledge in aesthetic medicine
            from the comfort of your home or office. Led by industry experts,
            these sessions offer insights into advanced techniques and trends,
            with ample opportunities for Q&A to ensure you leave equipped with
            practical skills and knowledge to enhance your practice.
          </p>

          {/* <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="full-video w-video w-embed margin-top-l"
          >
            <iframe
              className="embedly-embed"
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FX2rGgsqaq3A%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DX2rGgsqaq3A&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FX2rGgsqaq3A%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen=""
              title="Medspa Network training December 2023"
            ></iframe>
          </div> */}
        </div>
      </section>
      <section className="plum-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs light center underline">
            Upcoming Live Webinar
          </div>
          <div className="header-xxl light pad-top-m">Webinar</div>
          <div className="header-xxl">{newestEvent.fields.eventTitle}</div>
          <p className="body-l center pad-top-m">
            {newestEvent.fields.eventDescription}
          </p>
          <div className="featured-event">
            <img
              src={newestEvent.fields.bannerImage.fields.file.url}
              loading="lazy"
              alt=""
              className="featured-event-image"
            />
            <div className="featured-event-details plum">
              <div className="featured-event-title-bar">
                <div className="featured-event-title">
                  <div className="header-s light" style={{ color: "white" }}>
                    {newestEvent.fields.eventTitle}
                  </div>
                  <div className="date-badge-light general-1">
                    <div>Up Next</div>
                  </div>
                </div>
              </div>
              <div className="featured-event-details-bar">
                <div className="div-block-50">
                  <div className="badge">
                    <img
                      alt=""
                      src={require("../assets/icons/calendar-2.png")}
                      loading="lazy"
                      className="tag-icon"
                    />
                    <div className="eyebrow s light-aurora">
                      {moment(newestEvent.fields.eventStartDate).format(
                        "DD MMM YYYY"
                      )}
                    </div>
                  </div>
                </div>
                <Dense_Button_Light
                  link={
                    "/webinar/register?" +
                    new URLSearchParams(newestEvent.fields)
                  }
                  text="Register Now"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="header-xs light underline">Calendar</div>
          <div className="header-xl pad-top-l">Upcoming Webinars</div>
          <div className="events-list">
            {eventsList}
            {eventsList.length > eventsToDisplay ? (
              <div
                className="button m center"
                onClick={() => {
                  setEventsToDisplay(eventsToDisplay + 5);
                }}
              >
                Load More
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <IndutryPartnershipsSection
        backgroundColor={"lightgrey-background-section"}
      />
      <Footer />
    </>
  );
}
