import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import axios from "axios";
import { useTrainingContext } from "../context/clinicalTrainingContext";
import moment from "moment";
import { Dense_Button_Light } from "../components/buttons";
import Navbar from "../components/navigations";
import Footer from "../components/footer";

ReactGA.initialize("G-CVD1XQ5B0K");

export default function MasterclassCheckout() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const location = useLocation();
  const { trainingTicket, applyCoupon } = useTrainingContext();
  const [userDetails, setUserDetails] = useState({});
  const [cardDetails, setCardDetails] = useState({});
  const [warning, setWarning] = useState(null);
  const searchParams = new URLSearchParams(search);
  const queryParams = new URLSearchParams(location.search);
  console.log(queryParams);
  const eventDate = queryParams.get("date");
  const eventTitle = queryParams.get("title");
  const referral = searchParams.get("affiliate");
  let eventPrice;

  if (referral.toLowerCase() == "larkin") {
    eventPrice = 550;
  } else {
    eventPrice = 1950;
  }

  async function handleSubmit(e) {
    console.log("submitting");
    e.preventDefault();

    document.getElementById("submitButton").value = "Processing...";
    document.getElementById("submitButton").disabled = true;

    axios
      .post("/api/v2/Website/purchase/event-purchase", {
        cardDetails: cardDetails,
        userDetails: userDetails,
        price: eventPrice,
        event: "Hands-on Art of Regenerative Aesthetics Masterclass",
        eventDate: "2025-02-21",
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status != "success") {
          setWarning(res.data.message);
          document.getElementById("submitButton").value = "Submit";
          document.getElementById("submitButton").disabled = false;
        } else {
          ReactGA.event("purchase", {
            currency: "USD",
            transaction_id: cardDetails.number,
            value: 1950,
            coupon: userDetails.referralCode,
            items: ["Lab Registration"],
          });
          navigate(`/masterclass-thank-you`);
        }
      })
      .catch((err) => {
        console.log(err);
        setWarning(String(err));
        document.getElementById("submitButton").value = "Submit";
        document.getElementById("submitButton").disabled = false;
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    // if (!location.state) {
    //   navigate("/masterclass/february2025");
    // }
  }, []);

  return (
    <div>
      <Navbar type="dark" />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="vertical">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="form"
              data-wf-page-id="66f4597a34ff7685625ce5d6"
              data-wf-element-id="5dd738e7-aa57-5745-4a0a-f5575b02dd00"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <h1 className="heading-4">
                Hands-on Art of Regenerative Aesthetics Masterclass
              </h1>
              <div
                id="w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a89-625ce5d6"
                className="w-layout-layout quick-stack-10 wf-layout-layout"
              >
                <div
                  id="w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a8a-625ce5d6"
                  className="w-layout-cell masterclass-checkout-left-cell"
                  style={{
                    minHeight: "auto",
                    height: 100,
                    position: "relative",
                  }}
                >
                  <div className="pricing-card">
                    <div className="pricing-card-top">
                      <div
                        className="pricing-card-text"
                        style={{ color: "black" }}
                      >
                        2-Day
                      </div>
                      <div
                        className="pricing-card-text"
                        style={{ color: "black" }}
                      >
                        {eventTitle}
                      </div>
                      <div
                        className="pricing-card-price"
                        style={{ color: "black" }}
                      >
                        {Number(eventPrice).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 0,
                        })}
                      </div>

                      <div className="badge">
                        <img
                          src={require("../assets/icons/calendar-2.png")}
                          loading="lazy"
                          alt=""
                          className="tag-icon"
                        />
                        <div className="tag-text" style={{ color: "black" }}>
                          {eventDate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a8b-625ce5d6"
                  className="w-layout-cell masterclass-checkout-right-cell"
                >
                  <div className="form-block w-form">
                    <div className="div-block-28">
                      <div className="header-m light">About You</div>
                      <div className="div-block-29">
                        <label htmlFor="name" className="field-label-2">
                          First Name
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="First-Name"
                          data-name="First Name"
                          placeholder="First Name"
                          type="text"
                          id="First-Name"
                          required={true}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              firstName: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="div-block-30">
                        <label htmlFor="" className="field-label-2">
                          Last Name
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="Last-Name"
                          data-name="Last Name"
                          placeholder="Last Name"
                          type="text"
                          id="Last-Name"
                          required={true}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              lastName: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="Email" className="field-label-2">
                        Email Address
                      </label>
                      <input
                        className="checkout-field w-input"
                        maxLength="256"
                        name="Email"
                        data-name="Email"
                        placeholder="Enter your Email Address"
                        type="email"
                        id="Email"
                        required={true}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="mobile" className="field-label-2">
                        Phone Number
                      </label>
                      <input
                        className="checkout-field w-input"
                        maxLength="256"
                        name="mobile"
                        data-name="mobile"
                        placeholder="Phone Number"
                        type="tel"
                        id="mobile"
                        required={true}
                        onChange={(e) => {
                          setUserDetails({
                            ...userDetails,
                            mobile: e.target.value,
                          });
                        }}
                      />
                      <div>
                        <label htmlFor="" className="field-label-2">
                          NPI Number or License Number
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="license"
                          data-name="license"
                          placeholder="NPI or License"
                          type="text"
                          id="license"
                          required={true}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              license: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label htmlFor="referral" className="field-label-2">
                          Referral Source
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="referral"
                          data-name="referral"
                          placeholder="Pick One"
                          type="text"
                          id="referral"
                          required={true}
                          value={referral}
                          disabled={referral ? true : false}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              referral: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="header-m light" style={{ marginTop: 48 }}>
                        About Your Company{" "}
                      </div>
                      <div className="div-block-28">
                        <div className="div-block-29">
                          <label htmlFor="name-3" className="field-label-2">
                            Company Name
                          </label>
                          <input
                            className="checkout-field w-input"
                            maxLength="256"
                            name="company"
                            data-name="company"
                            placeholder="Example"
                            type="text"
                            id="company"
                            required={true}
                            onChange={(e) => {
                              setUserDetails({
                                ...userDetails,
                                company: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="div-block-30">
                          <label htmlFor="" className="field-label-2">
                            Company Website
                          </label>
                          <input
                            className="checkout-field w-input"
                            maxLength="256"
                            name="website"
                            data-name="website"
                            placeholder="www.example.com"
                            type="text"
                            id="website"
                            required={true}
                            onChange={(e) => {
                              setUserDetails({
                                ...userDetails,
                                website: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="header-m light" style={{ marginTop: 48 }}>
                        Credit Card Info
                      </div>
                      <div>
                        <label htmlFor="" className="field-label-2">
                          Billing Address
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="Billing-Address"
                          data-name="Name 2Billing Address"
                          placeholder="Billing Address"
                          type="text"
                          id="Billing-Address"
                          required={true}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              billingAddress: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label htmlFor="" className="field-label-2">
                          Billing City
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="Billing-City"
                          data-name="Billing City"
                          placeholder="Billing City"
                          type="text"
                          id="Billing-City"
                          required={true}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              billingCity: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="Billing-State"
                          className="field-label-2"
                        >
                          Billing State ( Two Digit Code )
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="2"
                          name="Billing-State"
                          data-name="Billing State"
                          placeholder="Billing State ( FL )"
                          type="text"
                          id="Billing-State"
                          required={true}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              billingState: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="Billing-Zip-Code"
                          className="field-label-2"
                        >
                          Billing Zip Code
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="Billing-Zip-Code"
                          data-name="Billing Zip Code"
                          placeholder="Billing Zip Code"
                          type="text"
                          id="Billing-Zip-Code"
                          required={true}
                          onChange={(e) => {
                            setUserDetails({
                              ...userDetails,
                              billingZipCode: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label htmlFor="" className="field-label-2">
                          First Name on Card
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="Card-First-Name"
                          data-name="Card First Name"
                          placeholder="First Name"
                          type="text"
                          id="Card-First-Name"
                          required={true}
                          onChange={(e) => {
                            setCardDetails({
                              ...cardDetails,
                              firstName: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="Card-Last-Name"
                          className="field-label-2"
                        >
                          Last Name on Card
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="Card-Last-Name"
                          data-name="Card Last Name"
                          placeholder="Last Name"
                          type="text"
                          id="Card-Last-Name"
                          required={true}
                          onChange={(e) => {
                            setCardDetails({
                              ...cardDetails,
                              lastName: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="expiration-date"
                          className="field-label-2"
                        >
                          Card Number
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="number"
                          data-name="number"
                          placeholder="**** **** **** ****"
                          type="text"
                          id="number"
                          required={true}
                          onChange={(e) => {
                            setCardDetails({
                              ...cardDetails,
                              number: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="expiration-date"
                          className="field-label-2"
                        >
                          Expiration Date (MMYYYY)
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="Expiration-Date"
                          data-name="Expiration Date"
                          placeholder="MMYYYY"
                          type="text"
                          id="Expiration-Date-2"
                          required={true}
                          onChange={(e) => {
                            setCardDetails({
                              ...cardDetails,
                              expiry: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label htmlFor="" className="field-label-2">
                          CVV
                        </label>
                        <input
                          className="checkout-field w-input"
                          maxLength="256"
                          name="CVV"
                          data-name="CVV"
                          placeholder="****"
                          type="text"
                          id="CVV"
                          required={true}
                          onChange={(e) => {
                            setCardDetails({
                              ...cardDetails,
                              cvc: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <p style={{ color: "red" }}>{warning}</p>
                      <button
                        className="button-dense-primary-light checkout-button"
                        type="submit"
                        id="submitButton"
                      >
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
