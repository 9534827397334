import Navbar from "../components/navigations";
import {
  Dense_button_dark,
  Dense_Button_Light,
  Medium_button_secondary_dark,
  Medium_button_secondary_light,
  Primary_button_dark,
  Primary_button_light,
} from "../components/buttons";
import CountUp from "../components/countUp";
import TestimonialsSection from "../components/testimonials-section";
import Footer from "../components/footer";
import FaqSection from "../components/faqSection";

export default function Membership() {
  const FAQLIST = [
    {
      question: "What do the CME courses cover?",
      answer:
        "Our CME courses cover a wide range of topics in aesthetic medicine, including injectables, laser treatments, skincare, and more. These courses are designed to help you stay up-to-date with the latest techniques and advancements in the field.",
    },
    {
      question: "Do i receieve course education materials?",
      answer:
        "Yes, all of our CME courses come with educational materials, including presentations, videos, and other resources to help you learn and retain the information. These materials are accessible online and can be reviewed at your own pace.",
    },
    {
      question: "Can i upgrade my membership?",
      answer:
        "Yes, you can upgrade your membership at any time by contacting our support team. We offer a range of membership options to suit your needs, including individual and group memberships.",
    },
    {
      question:
        "What differentiates MedSpa Network from other membership programs?",
      answer:
        "MedSpa Network is unique in that we offer a comprehensive range of resources, training, and community support to help you succeed in aesthetic medicine. Our membership program is designed to provide you with the tools and knowledge you need to excel in your career.",
    },
  ];

  return (
    <>
      <Navbar type="light" />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Membership</div>
          <div className="header-xxl">Get Your Membership</div>
          <div className="header-xxl light ">
            and Take the Next Step in Your Aesthetic Career.
          </div>
          <p className="body-l ">
            Our membership is designed to help you stay ahead in the
            fast-evolving field of aesthetic medicine, ensuring you have the
            skills, knowledge, and connections needed to excel.
          </p>
          <div className="button-group">
            <Dense_button_dark link="/create-account" text="Create Account" />
            <Dense_Button_Light link="/about" text=" Learn More" />
          </div>
          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="full-video w-video w-embed"
          >
            <iframe
              className="embedly-embed"
              src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F0IJQZqrJw94&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D0IJQZqrJw94&image=http%3A%2F%2Fi.ytimg.com%2Fvi%2F0IJQZqrJw94%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen=""
              title="Medspa Network training December 2023"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">
            Learning and Development
          </div>
          <div className="header-xl pad-top-l">
            Enhance Your Knowledge and Skills.
          </div>
          <div className="header-xl light ">
            We Believe That Continuous Learning is key to Success. Our
            Membership Offers a Range of Educational Resources That Keep you
            Informed and Help you Stay Ahead in Your Career.
          </div>
          <div
            id="w-node-a534e4f9-b38b-c08b-2756-cbdda0858cd3-f5256702"
            className="w-layout-layout three-stack  wf-layout-layout"
          >
            <div
              id="w-node-_621b9db7-8440-85d9-9d5d-3ce4a5694c91-f5256702"
              className="w-layout-cell cell-8"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../assets/images/online_cme.png")}
                  alt=""
                  className="paragraph-card-image large"
                />
                <h2 className="paragraph-card-heading">Online CME Courses</h2>
                <p className="paragraph-card-details">
                  Stay informed and up-to-date with our online CME courses.
                  Learn at your own pace while earning credits that contribute
                  to your professional development.
                </p>
              </div>
            </div>
            <div
              id="w-node-_621b9db7-8440-85d9-9d5d-3ce4a5694c98-f5256702"
              className="w-layout-cell cell-7"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../assets/images/webinars_durkin.png")}
                  alt=""
                  className="paragraph-card-image"
                />
                <h2 className="paragraph-card-heading">Exclusive Webinars</h2>
                <p className="paragraph-card-details">
                  Join members-only webinars led by experienced professionals.
                  These sessions provide in-depth knowledge of the latest
                  techniques and industry developments.
                </p>
              </div>
            </div>
            <div
              id="w-node-_621b9db7-8440-85d9-9d5d-3ce4a5694c9f-f5256702"
              className="w-layout-cell cell-7"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../assets/images/top_trainers.png")}
                  alt=""
                  className="paragraph-card-image large"
                />
                <h2 className="paragraph-card-heading">
                  Access to top Trainers
                </h2>
                <p className="paragraph-card-details">
                  Connect directly with some of the best trainers in the
                  country. Gain insights and guidance that can help you refine
                  your practice and grow your expertise.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">
            Community Forum Access
          </div>
          <div className="header-xl pad-top-l">Connect, Share</div>
          <div className="header-xl light">and Grow Together</div>
          <p className="body-l ">
            Join our private forum and become part of a thriving community of
            aesthetic professionals. This exclusive space allows you to ask
            questions, share your experiences, and connect with peers and
            experts in the field. Our forum is designed to be a supportive
            environment where continuous learning and collaboration are at the
            forefront.
          </p>
          <div
            id="w-node-_70a8bbcc-b4b5-b0ef-8b37-90d876486b0b-f5256702"
            className="w-layout-layout full-width-stack pad-top-l wf-layout-layout"
          >
            <div
              id="w-node-a817180f-b293-cbfa-96b0-c761c95cde63-f5256702"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">
                Ask Questions <br />
                and Get Answers
              </div>
              <p className="body-m ">
                Your well-being is our top priority. We adhere to the highest
                safety standards in all our procedures and training sessions,
                ensuring you can trust the quality of care you receive.
              </p>
            </div>
            <div
              id="w-node-a817180f-b293-cbfa-96b0-c761c95cde6a-f5256702"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">
                Share Your <br />
                Experiences
              </div>
              <p className="body-m ">
                Honesty and openness are at the heart of our operations. We
                maintain high ethical standards and provide transparent
                communication, so you feel confident and informed at every step.
              </p>
            </div>
            <div
              id="w-node-a817180f-b293-cbfa-96b0-c761c95cde71-f5256702"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">
                Collaborate <br />
                and Learn
              </div>
              <p className="body-m ">
                We stay at the forefront of aesthetic medicine through ongoing
                learning and innovation. Our commitment to the latest
                advancements ensures you receive the most current techniques and
                knowledge.
              </p>
            </div>
            <div
              id="w-node-a817180f-b293-cbfa-96b0-c761c95cde78-f5256702"
              className="w-layout-cell"
            ></div>
          </div>
        </div>
      </section>
      <section className="green-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline light">
            Savings and Benefits
          </div>
          <div className="header-xl ">
            Networks of Aesthetic Practitioners in the USA.
          </div>
          <p className="body-l ">
            As a member of MedSpa Network, you’ll enjoy exclusive discounts and
            special offers that help you make the most of your practice. We
            provide you with the financial benefits that make quality products
            and training more accessible.
          </p>
          <div
            id="w-node-bc4e0c1b-c760-b800-b765-3a76cd180945-f5256702"
            className="w-layout-layout three-stack  wf-layout-layout"
          >
            <div
              id="w-node-bc4e0c1b-c760-b800-b765-3a76cd180946-f5256702"
              className="w-layout-cell cell-8"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../assets/images/Medspa_Network_product.png")}
                  alt=""
                  className="paragraph-card-image large"
                />
                <h2 className="paragraph-card-heading">Product Discounts</h2>
                <p className="paragraph-card-details">
                  Learn from board-certified plastic surgeons and renowned
                  medical professionals, gaining both knowledge and a supportive
                  network of peers and mentors.
                </p>
              </div>
            </div>
            <div
              id="w-node-bc4e0c1b-c760-b800-b765-3a76cd18094d-f5256702"
              className="w-layout-cell cell-7"
            >
              <div className="div-block-10">
                <img
                  loading="lazy"
                  src={require("../assets/images/stephanie_beiber_injecting.png")}
                  alt=""
                  className="paragraph-card-image"
                />
                <h2 className="paragraph-card-heading">
                  In-Person Training Discounts
                </h2>
                <p className="paragraph-card-details">
                  Master the latest non-surgical aesthetic procedures alongside
                  a community of professionals dedicated to advanced techniques
                  and patient safety.
                </p>
              </div>
            </div>
            <div
              id="w-node-_1fb94ae7-5809-d41e-faff-b0462c4986c7-f5256702"
              className="w-layout-cell"
            ></div>
          </div>
        </div>
      </section>
      <section className="green-background-section">
        <div className="w-layout-blockcontainer container ">
          <div
            id="w-node-baf977d2-a00a-51e6-8143-d91326088cae-f5256702"
            className="w-layout-layout full-width-stack pad-top-xl wf-layout-layout"
          >
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088caf-f5256702"
              className="w-layout-cell stat-item"
            >
              <div>
                <div className="header">
                  <CountUp number={30} additionalText="" />
                </div>
                <p className="body-s light">
                  Webinars hosted annually, keeping you at the forefront of
                  industry
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cb7-f5256702"
              className="w-layout-cell stat-item"
            >
              <div>
                <div className="header">
                  <CountUp number={20} additionalText="%" />
                </div>
                <p className="body-s light">
                  Average discount on high-quality products and in-person
                  training
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cbf-f5256702"
              className="w-layout-cell stat-item"
            >
              <div>
                <div className="header">
                  <CountUp number={1000} additionalText="+" />
                </div>
                <p className="body-s light">
                  Medical professionals trained through our comprehensive
                  courses
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FaqSection
        backgroundColor="white-background-section"
        FAQList={FAQLIST}
      />

      <section className="light-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">
            Ready to Grow With Us?
          </div>
          <div className="header-xl pad-top-l">Get Your Membership Today.</div>
          <div className="header-xl light ">
            Join MedSpa for $1,495/year and gain access to the Resources,
            Training, and Community you need to succeed in Aesthetic Medicine.
          </div>
          <div className="button-group">
            <Dense_button_dark link="/create-account" text="Create Account" />
            <Dense_Button_Light link="/about" text=" Learn More" />
          </div>
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </>
  );
}
