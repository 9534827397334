import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { useRef, useState } from "react";
import axios from "axios";

export default function Contact() {
  const ref = useRef();
  const [formData, setFormData] = useState({});
  const [returnText, setReturnText] = useState(null);
  document.title = "Contact Us | Medspa Network";

  async function onSubmit(e) {
    console.log("submit");
    e.preventDefault();
    const button = document.getElementById("submit");
    button.disabled = true;
    button.value = "Please wait...";

    axios
      .post("/api/v2/emails/contact-us", formData)
      .then((res) => {
        console.log(res.data);
        if (res.data.ok) {
          setReturnText(true);
          button.disabled = false;
          button.value = "Submit";
        } else {
          setReturnText(false);
          button.disabled = false;
          button.value = "Failed to submit";
        }
        ref.current.scrollIntoView();
      })
      .catch((err) => {
        console.log(err);
        button.disabled = false;
        button.value = "Failed to submit";
      });
  }

  return (
    <>
      <Navbar type="" />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify">
          <div className="header-xs title-underline">Contact</div>
          <div
            id="w-node-aa643880-0aa8-704a-aed3-1b7c21207061-b3efc38c"
            className="w-layout-layout full-width-stack pad-top-l wf-layout-layout"
          >
            <div
              id="w-node-aa643880-0aa8-704a-aed3-1b7c21207062-b3efc38c"
              className="w-layout-cell"
            >
              <div className="header-xl">Get In Touch With Us</div>
            </div>
            <div
              id="w-node-aa643880-0aa8-704a-aed3-1b7c21207063-b3efc38c"
              className="w-layout-cell"
            >
              {returnText == null ? (
                <div className="form w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    data-wf-page-id="670eb3396b12ff37b3efc38c"
                    data-wf-element-id="026e9d42-3ecd-26b6-548b-7e82c3c97028"
                    onSubmit={(e) => {
                      onSubmit(e);
                    }}
                  >
                    <div className="field-group">
                      <label htmlFor="name-4" className="field-label-2">
                        Name
                      </label>
                      <input
                        required
                        className="checkout-field light "
                        style={{ width: "100%" }}
                        maxLength="256"
                        name="name"
                        data-name="name"
                        placeholder=""
                        type="text"
                        id="name-4"
                        onChange={(e) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="field-group">
                      <label htmlFor="email" className="field-label-2">
                        Email
                      </label>
                      <input
                        required
                        className="checkout-field light "
                        style={{ width: "100%" }}
                        maxLength="256"
                        name="email"
                        data-name="email"
                        placeholder=""
                        type="email"
                        id="email"
                        onChange={(e) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="field-group">
                      <label htmlFor="phoneNumber" className="field-label-2">
                        Phone Number
                      </label>
                      <input
                        required
                        className="checkout-field light "
                        style={{ width: "100%" }}
                        maxLength="256"
                        name="phoneNumber"
                        data-name="phoneNumber"
                        placeholder=""
                        type="text"
                        id="phoneNumber"
                        onChange={(e) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="field-group">
                      <label htmlFor="content" className="field-label-2">
                        Your Message
                      </label>
                      <textarea
                        required
                        placeholder="Example Text"
                        style={{ width: "100%" }}
                        maxLength="5000"
                        id="content"
                        name="content"
                        data-name="content"
                        className="checkout-field light "
                        onChange={(e) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      ></textarea>
                    </div>
                    <input
                      type="submit"
                      data-wait="Please wait..."
                      className="button-dense-primary-dark "
                      value="Submit"
                      id="submit"
                    />
                  </form>
                </div>
              ) : null}
              {returnText == null ? null : returnText == true ? (
                <section
                  id="returnSection"
                  ref={ref}
                  className="contact-return-section"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="header-xl">
                    Thank you for reaching out to MedSpa Network.
                  </div>
                  <div className="header-s pad-top-s">
                    We will be in touch soon!
                  </div>
                </section>
              ) : (
                <section
                  ref={ref}
                  id="returnSection"
                  className="contact-return-section"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="header-xl">Something went wrong</div>
                  <div className="header-s pad-top-s">
                    Refresh the page and try again.
                  </div>
                </section>
              )}
            </div>
          </div>
          <div
            id="w-node-cd22e3e1-eff0-7189-bbce-71074a68b304-b3efc38c"
            className="w-layout-layout full-width-stack pad-top-l wf-layout-layout"
          >
            <div
              id="w-node-_91013145-52ce-564a-4168-93f911ea5ae2-b3efc38c"
              className="w-layout-cell"
            >
              <div className="info-card">
                <h4 className="header-s regular">Email</h4>
                <div>
                  <p className="body-s">
                    Reach out to us via email for any inquiries or support
                    needs.
                  </p>
                  <div className="div-block-15">
                    <a
                      href="mailto:info@medspanetwork.com"
                      className="button-m"
                    >
                      Info@medspanetwork.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_91013145-52ce-564a-4168-93f911ea5aed-b3efc38c"
              className="w-layout-cell"
            >
              <div className="info-card">
                <h4 className="header-s regular">Socials</h4>
                <div>
                  <p className="body-s">
                    Follow us on social media to stay updated with the latest
                    news.
                  </p>
                  <div className="div-block-15">
                    <div className="footer-social-bar">
                      <a
                        href="https://www.instagram.com/medspanetwork/"
                        className="footer-social-link w-inline-block"
                      >
                        <img
                          src={require("../assets/logos/instagram-logo-1.png")}
                          loading="lazy"
                          alt=""
                          className="footer-social-icon"
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/medspanetwork/"
                        className="footer-social-link w-inline-block"
                      >
                        <img
                          src={require("../assets/logos/facebook-1.png")}
                          loading="lazy"
                          alt=""
                          className="footer-social-icon"
                        />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/medspa-network"
                        className="footer-social-link w-inline-block"
                      >
                        <img
                          src={require("../assets/logos/linkedin.png")}
                          loading="lazy"
                          alt=""
                          className="footer-social-icon"
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCLLWwCFZHtGF99XmxUF8leg"
                        className="footer-social-link w-inline-block"
                      >
                        <img
                          src={require("../assets/logos/youtube-2.png")}
                          loading="lazy"
                          alt=""
                          className="footer-social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_91013145-52ce-564a-4168-93f911ea5af8-b3efc38c"
              className="w-layout-cell"
            >
              <div className="info-card">
                <h4 className="header-s regular">Phone</h4>
                <div>
                  <p className="body-s">
                    Give us a call for immediate assistance or to speak directly
                    with our team.
                  </p>
                  <div className="div-block-15">
                    <a href="tel:+17722589650" className="button-m">
                      772-258-9650
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_91013145-52ce-564a-4168-93f911ea5b03-b3efc38c"
              className="w-layout-cell"
            >
              <div className="info-card">
                <h4 className="header-s regular">Address</h4>
                <div>
                  <p className="body-s">
                    Visit our office for in-person consultations and support.
                  </p>
                  <div className="div-block-15">
                    <a href="#" className="button-m">
                      5070 Hwy A1A
                      <br />
                      Vero Beach, FL 32963
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
