import { UserContextProvider, useUserContext } from "./context/userContext.js";
import { ShoppingCartProvider } from "./context/ShoppingCartContext.js";
import { TrainingProvider } from "./context/clinicalTrainingContext.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import AlertBar from "./components/alertBar.js";
import UserTracking from "./hooks/googleAnalytics.js";
import { AuthProvider } from "./context/authContext.js";

import "./assets/styles/modal.css";
import "./assets/styles/hamburger.css";
import "./css/medspa-network-v3.webflow.css";
import "./css/webflow.css";
import "./css/normalize.css";
import "./css/globals.css";
import "./css/calendar.css";

import Error from "./layout/404.js";
import Masterclass from "./layout/masterclass.js";
import Login from "./layout/login.js";
import MyAccount from "./layout/account/my-account.js";
import Terms from "./layout/terms.js";
import Privacy from "./layout/privacy.js";
import Contact from "./layout/contact";
import Shop from "./layout/shop";
import AboutUs from "./layout/about.js";
import Team from "./layout/team.js";
import EventCalendar from "./layout/event-calendar.js";
import InPerson from "./layout/inPerson.js";
import FillerFundamentals from "./layout/In-Person-Courses/filler-fundamentals.js";
import Course from "./layout/course.js";
import EventThankYou from "./layout/event-thank-you.js";
import Homev3 from "./layout/home.js";
import MasterclassCheckout from "./layout/masterclass-checkout.js";
import GlpInformation from "./layout/glp-information.js";
import Membership from "./layout/membership.js";
import Checkout from "./layout/paidCheckout.js";
import Events from "./layout/events/events.js";
import Webinars from "./layout/webinars.js";
import WebinarRegistration from "./layout/webinarRegistration.js";
import CookieConsent from "./components/cookie-consent.js";
import Signup from "./layout/signup.js";
import MembershipCheckout from "./layout/membership-checkout.js";
import SuccessfulAccountCreation from "./layout/successful-account-creation.js";
import Payments from "./layout/account/payments.js";
import TeamSpotlight from "./layout/team-spotlight.js";
import Partners from "./layout/partners.js";
import PartnerSpotlight from "./layout/partner-spotlight.js";
import Courses from "./layout/courses.js";
import TestUtil from "./layout/testUtil.js";
import Certificates from "./layout/account/certificates.js";
import CourseCompletion from "./layout/course-completion.js";
import Quiz from "./layout/quiz.js";
import WebinarDetails from "./layout/webinarDetails.js";
import AccountMembership from "./layout/account/membership.js";
import Leaving from "./layout/leaving.js";
import MasterclassThankYou from "./layout/masterclass-thankyou.js";
import GlpSignup from "./layout/glp-signup.js";
import SocietyEvents from "./layout/events/society.js";
import IndustryEvents from "./layout/events/industry.js";
import TrainingEvents from "./layout/events/training.js";
import GlpInformationNew from "./layout/glp-information-new.js";
import Article from "./layout/article.js";
import Blogs from "./layout/blogs.js";
import Product from "./layout/product.js";
import GLPInformationVIO from "./layout/glp-information-vio.js";
import SponsorshipApplication from "./layout/sponsorship-application.js";
import SponsorCheckout from "./layout/sponsorship-checkout.js";
import SponsorThankYou from "./layout/sponsorship-thankyou.js";
import Cart from "./layout/cart.js";
import CartThankYou from "./layout/cart-thankyou.js";
import AffiliateProduct from "./layout/crown-affiliate-product.js";
import CrownAffiliateProduct from "./layout/crown-affiliate-product.js";
import RohrerAffiliateProduct from "./layout/rohrer-affiliate-product.js";

// function RequireAuth({ children }) {
//   return authed ? children : <Navigate to="/" state={{ from: location }} />;
// }

function App() {
  const { createMemoryHistory } = require("history");
  const history = createMemoryHistory();

  UserTracking(window.location.pathname + window.location.search);

  const cookie_consent = localStorage.getItem("cookie_consent");
  return (
    <div className="App" style={{ overflowX: "hidden", position: "relative" }}>
      <AuthProvider>
        <TrainingProvider>
          <UserContextProvider>
            <ShoppingCartProvider>
              <BrowserRouter
                location={history.location}
                navigator={history}
                future={{ v7_startTransition: true }}
              >
                {/* {cookie_consent === "true" ? null : <CookieConsent />} */}

                <Routes>
                  <Route index element={<Homev3 />} />
                  <Route path="/membership" element={<Membership />} />
                  <Route
                    path="/membership-checkout"
                    element={<MembershipCheckout />}
                  />
                  <Route
                    path="/successful-account-creation"
                    element={<SuccessfulAccountCreation />}
                  />
                  <Route path="checkout" element={<Checkout />} />
                  <Route path="/events" element={<Events />} />
                  <Route path="/in-person-learning" element={<InPerson />} />
                  <Route path="/webinars" element={<Webinars />} />
                  <Route path="/webinar/:id" element={<WebinarDetails />} />
                  <Route
                    path="/webinar/register"
                    element={<WebinarRegistration />}
                  />
                  <Route
                    path="/in-person-learning/filler-fundamentals"
                    element={<FillerFundamentals />}
                  />
                  <Route
                    path="/team-spotlight/:staff"
                    element={<TeamSpotlight />}
                  />
                  <Route path="/create-account" element={<Signup />} />
                  <Route path="/leaving" element={<Leaving />} />
                  <Route path="/account" element={<MyAccount />} />
                  <Route
                    path="/my-membership"
                    element={<AccountMembership />}
                  />
                  <Route path="/certificates" element={<Certificates />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route path="/industry-partners" element={<Partners />} />
                  <Route
                    path="/partner/:partner"
                    element={<PartnerSpotlight />}
                  />
                  <Route path="/online-courses" element={<Courses />} />
                  <Route path="/course" element={<Course />} />
                  <Route
                    path="/completed-course"
                    element={<CourseCompletion />}
                  />
                  <Route path="/quiz" element={<Quiz />} />
                  <Route path="/testUtil" element={<TestUtil />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/course/:id" element={<Course />} />
                  <Route path="/shop" element={<Shop />} />
                  <Route path="/product/:id" element={<Product />} />
                  <Route
                    path="/product/crown/:id"
                    element={<CrownAffiliateProduct />}
                  />
                  <Route
                    path="/product/rohrer/:id"
                    element={<RohrerAffiliateProduct />}
                  />
                  <Route path="/cart" element={<Cart />} />
                  <Route
                    path="/purchase-thank-you"
                    element={<CartThankYou />}
                  />
                  <Route path="/industry-partners" element={<Partners />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route
                    path="/masterclass-thank-you"
                    element={<MasterclassThankYou />}
                  />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/eventThankYou" element={<EventThankYou />} />
                  <Route path="/semaglutide" element={<GlpSignup />} />
                  <Route path="/glp-signup" element={<GlpSignup />} />
                  <Route path="/semaglutideSignup" element={<GlpSignup />} />
                  <Route path="/glp-information" element={<GlpInformation />} />
                  <Route path="/vio-glp" element={<GLPInformationVIO />} />
                  <Route
                    path="/glp-information-new"
                    element={<GlpInformationNew />}
                  />
                  <Route path="/masterclass/:id" element={<Masterclass />} />
                  <Route
                    path="/masterclass-checkout"
                    element={<MasterclassCheckout />}
                  />
                  <Route
                    path="/masterclass/vendors"
                    element={<SponsorshipApplication />}
                  />
                  <Route
                    path="/sponsor-checkout"
                    element={<SponsorCheckout />}
                  />
                  <Route
                    path="/sponsor-thank-you"
                    element={<SponsorThankYou />}
                  />
                  <Route path="/event-calendar" element={<EventCalendar />} />
                  <Route path="/events/:type" element={<Events />} />
                  <Route path="/society-Events" element={<SocietyEvents />} />
                  <Route path="/industry-Events" element={<IndustryEvents />} />
                  <Route path="/training-Events" element={<TrainingEvents />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/article/:id" element={<Article />} />
                  <Route path="/glp-information" element={<GlpInformation />} />
                  <Route path="*" element={<Error />} />
                </Routes>
              </BrowserRouter>
            </ShoppingCartProvider>
          </UserContextProvider>
        </TrainingProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
