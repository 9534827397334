export default function PartnerProductCard({
  image,
  name,
  price,
  description,
  tag,
}) {
  return (
    <>
      <div className="partner-product">
        <img
          loading="lazy"
          src={"/content/logos/" + image}
          alt=""
          className="partner-product-image"
        />
        <div className="div-block-49">
          <div className="partner-product-title">
            <p className="body-s">{name}</p>
            <p className="body-s extra-thin">{tag}</p>
          </div>
          <div className="header-xs">
            {price == 0
              ? "Curently not in our store"
              : price.toLocaleString("usd", {
                  currency: "USD",
                  style: "currency",
                })}
          </div>
          <p class="body-m">{description}</p>

          {/* <a href="#" className="button-dense-primary-dark ">
            Add To Cart
          </a> */}
        </div>
      </div>
    </>
  );
}
