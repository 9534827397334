import axios from "axios";
const userData = localStorage.getItem("user");
const user = JSON.parse(userData);

export default function Members() {}

export async function GetAll(params) {
  try {
    const res = await axios.get("/api/v2/Website/products", {
      ...params,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function GetProductById(id) {
  try {
    const res = await axios.get("/api/v2/Website/products/get/" + id);
    return res.data.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
