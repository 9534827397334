import axios from "axios";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Cart() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { cartItems, removeFromCart, decreaseQuantity, increaseQuantity } =
    useShoppingCart();
  const [warning, setWarning] = useState(null);
  const [cartTotals, setCartTotals] = useState({
    total: 0,
    tax: 0,
    shipping: 0,
  });
  const [purchaseData, setPurchaseData] = useState({
    cart: cartItems,
    shipping: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      address: "",
      city: "",
      state: "",
      zipCode: "",
    },
    billing: {},
    payment: {},
    event: "Cart Purchase",
  });

  async function totalCart() {
    const total = cartItems.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);
    setCartTotals({
      total: total,
      tax: total * 0.07,
      shipping: total * 0.01,
    });
  }

  async function submitPurchase(e) {
    console.log(purchaseData);
    e.preventDefault();

    axios
      .post("/api/v2/Website/purchase/cart-purchase", {
        ...purchaseData,
        cartTotals: cartTotals,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          setWarning("Purchase Successful");
          navigate("/purchase-thank-you");
        } else {
          setWarning("Purchase Failed");
        }
      });
  }

  useEffect(() => {
    totalCart();
    console.log(cartItems);
  }, [cartItems]);

  return (
    <>
      <Navbar />
      <form
        id="email-form"
        name="email-form"
        data-name="Email Form"
        method="get"
        data-wf-page-id="678915c8f12b9d3a751c1870"
        data-wf-element-id="17b65f64-3f94-b741-733c-75629b7b3974"
        onSubmit={(e) => {
          submitPurchase(e);
        }}
      >
        <section className="white-background-section">
          <div className="w-layout-blockcontainer container left-justify w-container">
            <div className="header-xxl">Cart</div>
            <div className="w-layout-hflex cart-container pad-top-m">
              <div className="w-layout-vflex flex-block-8">
                <div className="w-layout-vflex flex-block-7">
                  <div className="header-xs product-summary">
                    Product Summary
                  </div>
                  {cartItems.map((item, index) => {
                    return (
                      <div key={index} className="product-summary-line-item">
                        <img
                          src={
                            "/content/product-images/" +
                            item.company +
                            "/resized/" +
                            item.fileName +
                            ".webp"
                          }
                          loading="lazy"
                          alt=""
                          class="image-15"
                          style={{ width: 100 }}
                        ></img>
                        <a
                          href={`/product/${item._id}`}
                          className="body-s bold"
                          style={{ maxWidth: "20ch", width: 125 }}
                        >
                          {item.title}
                        </a>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 24,
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            justifyItems: "center",
                          }}
                        >
                          <p className="body-s">
                            {item.price.toLocaleString("USD", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </p>
                          <div
                            className="product-summary-line-item-quatity"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              alignContent: "center",
                              justifyItems: "center",
                            }}
                          >
                            <button
                              style={{ padding: 10, cursor: "pointer" }}
                              type="button"
                              className="button-2"
                              onClick={() => decreaseQuantity(item)}
                            >
                              -
                            </button>
                            <div className="form-block-2 ">
                              <p className="body-s bold">{item.quantity}</p>
                            </div>
                            <button
                              className="button-2"
                              type="button"
                              style={{ padding: 10, cursor: "pointer" }}
                              onClick={() => {
                                increaseQuantity(item);
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <p
                          className="body-s bold"
                          style={{ margin: 0, padding: 10 }}
                        >
                          {Number(item.price * item.quantity).toLocaleString(
                            "USD",
                            { style: "currency", currency: "USD" }
                          )}
                        </p>
                        <button
                          type="button"
                          className="button-dense-secondary-light "
                          onClick={() => removeFromCart(item._id)}
                        >
                          Remove
                        </button>
                      </div>
                    );
                  })}
                </div>
                <button
                  className="button-dense-primary-light"
                  type="button"
                  onClick={() => navigate("/shop")}
                >
                  Continue Shopping
                </button>
                <div className="w-layout-vflex flex-block-7">
                  <div className="header-xs product-summary">
                    Shipping Information
                  </div>

                  <div className="form-block-3 w-form">
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label
                          htmlFor="shippingFirstName"
                          className="field-label-2"
                        >
                          First Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingFirstName"
                          data-name="shippingFirstName"
                          defaultValue={user.firstName}
                          type="text"
                          id="shippingFirstName"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              shipping: {
                                ...purchaseData.shipping,
                                firstName: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="shippingLastName"
                          className="field-label-2"
                        >
                          Last Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingLastName"
                          data-name="shippingLastName"
                          defaultValue={user.lastName}
                          type="text"
                          id="shippingLastName"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              shipping: {
                                ...purchaseData.shipping,
                                lastName: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label
                          htmlFor="shippingEmail"
                          className="field-label-2"
                        >
                          Email
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingEmail"
                          data-name="shippingEmail"
                          defaultValue={user.email}
                          type="email"
                          id="shippingEmail"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              shipping: {
                                ...purchaseData.shipping,
                                email: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="shippingPhoneNumber"
                          className="field-label-2"
                        >
                          Phone Number
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingPhoneNumber"
                          data-name="shippingPhoneNumber"
                          defaultValue={user.phoneNumber}
                          type="tel"
                          id="shippingPhoneNumber"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              shipping: {
                                ...purchaseData.shipping,
                                phoneNumber: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label
                          htmlFor="shippingAddress"
                          className="field-label-2"
                        >
                          Shipping Address
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingAddress"
                          data-name="shippingAddress"
                          defaultValue=""
                          type="text"
                          id="shippingAddress"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              shipping: {
                                ...purchaseData.shipping,
                                address: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="shippingCity" className="field-label-2">
                          Shipping City
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingCity"
                          data-name="shippingCity"
                          defaultValue=""
                          type="text"
                          id="shippingCity"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              shipping: {
                                ...purchaseData.shipping,
                                city: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label
                          htmlFor="shippingState"
                          className="field-label-2"
                        >
                          Shipping State (2 letter abbreviation)
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="2"
                          name="shippingState"
                          data-name="shippingState"
                          defaultValue=""
                          type="text"
                          id="shippingState"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              shipping: {
                                ...purchaseData.shipping,
                                state: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="shippingZipCode"
                          className="field-label-2"
                        >
                          Shipping Zip Code
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingZipCode"
                          data-name="shippingZipCode"
                          defaultValue="XXXXX"
                          type="number"
                          id="shippingZipCode"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              shipping: {
                                ...purchaseData.shipping,
                                zipCode: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="field-group">
                      <label htmlFor="medicalLicense" className="field-label-2">
                        Purchaser Medical License or NPI Number
                      </label>
                      <input
                        className="checkout-field light w-input"
                        maxLength="256"
                        name="medicalLicense"
                        data-name="medicalLicense"
                        defaultValue={user.npiNumber}
                        type="text"
                        id="medicalLicense"
                        required
                        onChange={(e) => {
                          setPurchaseData({
                            ...purchaseData,
                            shipping: {
                              ...purchaseData.shipping,
                              medicalLicense: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-layout-vflex flex-block-7">
                  <div className="header-xs product-summary">
                    Billing Information
                  </div>
                  <div className="form-block-3 w-form">
                    <label className="w-checkbox checkbox-field">
                      <input
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        data-name="Checkbox"
                        className="w-checkbox-input"
                        onClick={(e) => {
                          if (e.target.checked) {
                            setPurchaseData({
                              ...purchaseData,
                              billing: purchaseData.shipping,
                            });
                          } else {
                            setPurchaseData({
                              ...purchaseData,
                              billing: {
                                firstName: null,
                                lastName: null,
                                address: null,
                                city: null,
                                state: null,
                                zipCode: null,
                              },
                            });
                          }
                        }}
                      />
                      <span className="w-form-label" htmlFor="checkbox">
                        Billing Address is the same as shipping
                      </span>
                    </label>
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label
                          htmlFor="shippingFirstName-2"
                          className="field-label-2"
                        >
                          First Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingFirstName-2"
                          data-name="Shipping First Name 2"
                          defaultValue={purchaseData.billing.firstName}
                          type="text"
                          id="shippingFirstName-2"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              billing: {
                                ...purchaseData.billing,
                                firstName: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="shippingLastName-2"
                          className="field-label-2"
                        >
                          Last Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingLastName-2"
                          data-name="Shipping Last Name 2"
                          defaultValue={purchaseData.billing.lastName}
                          type="text"
                          id="shippingLastName-2"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              billing: {
                                ...purchaseData.billing,
                                lastName: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label
                          htmlFor="shippingAddress-2"
                          className="field-label-2"
                        >
                          Billing Address
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingAddress-2"
                          data-name="Shipping Address 2"
                          defaultValue={purchaseData.billing.address}
                          type="text"
                          id="shippingAddress-2"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              billing: {
                                ...purchaseData.billing,
                                address: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="shippingCity-2"
                          className="field-label-2"
                        >
                          Billing City
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingCity-2"
                          data-name="Shipping City 2"
                          defaultValue={purchaseData.billing.city}
                          type="text"
                          id="shippingCity-2"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              billing: {
                                ...purchaseData.billing,
                                city: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label
                          htmlFor="shippingState-2"
                          className="field-label-2"
                        >
                          Billing State
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="2"
                          name="shippingState-2"
                          data-name="Shipping State 2"
                          defaultValue={purchaseData.billing.state}
                          type="text"
                          id="shippingState-2"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              billing: {
                                ...purchaseData.billing,
                                state: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="shippingZipCode-2"
                          className="field-label-2"
                        >
                          Billing Zip Code
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="shippingZipCode-2"
                          data-name="Shipping Zip Code 2"
                          defaultValue={purchaseData.billing.zipCode}
                          type="number"
                          id="shippingZipCode-2"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              billing: {
                                ...purchaseData.billing,
                                zipCode: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-layout-vflex flex-block-7">
                  <div className="header-xs product-summary">
                    Payment Method
                  </div>
                  <div className="form-block-3 w-form">
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label
                          htmlFor="cardFirstName"
                          className="field-label-2"
                        >
                          Card First Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="cardFirstName"
                          data-name="cardFirstName"
                          defaultValue=""
                          type="text"
                          id="cardFirstName"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              payment: {
                                ...purchaseData.payment,
                                firstName: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label htmlFor="lastName-2" className="field-label-2">
                          Card Last Name
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="cardLastName"
                          data-name="cardLastName"
                          defaultValue=""
                          type="text"
                          id="cardLastName"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              payment: {
                                ...purchaseData.payment,
                                lastName: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label htmlFor="cardNumber" className="field-label-2">
                          Card Number
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="cardNumber"
                          data-name="cardNumber"
                          defaultValue="XXXX XXXX XXXX XXXX"
                          type="number"
                          id="cardNumber"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              payment: {
                                ...purchaseData.payment,
                                cardNumber: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group">
                        <label
                          htmlFor="cardExpiration"
                          className="field-label-2"
                        >
                          Card Expiration (MM/YYYY)
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="cardExpiration"
                          data-name="cardExpiration"
                          defaultValue=""
                          type="text"
                          id="cardExpiration"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              payment: {
                                ...purchaseData.payment,
                                expiration: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-layout-hflex horizontal-field-group">
                      <div className="field-group">
                        <label htmlFor="cardCVV" className="field-label-2">
                          CVV
                        </label>
                        <input
                          className="checkout-field light w-input"
                          maxLength="256"
                          name="cardCVV"
                          data-name="cardCVV"
                          defaultValue="XXXX"
                          type="number"
                          id="cardCVV"
                          required
                          onChange={(e) => {
                            setPurchaseData({
                              ...purchaseData,
                              payment: {
                                ...purchaseData.payment,
                                cvv: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className="field-group"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-vflex purchase-summary-container">
                <div className="header-xs">Purchase Summary</div>
                <div className="div-block-68">
                  <div className="w-layout-hflex purchase-summary-line">
                    <div className="eyebrow s">Sub Total</div>
                    <div className="eyebrow s">
                      {cartTotals.total.toLocaleString("USD", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </div>
                  </div>
                  <div className="w-layout-hflex purchase-summary-line">
                    <div className="eyebrow s">Tax</div>
                    <div className="eyebrow s">
                      {cartTotals.tax.toLocaleString("USD", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </div>
                  </div>
                  <div className="w-layout-hflex purchase-summary-line">
                    <div className="eyebrow s">Shipping</div>
                    <div className="eyebrow s">
                      {cartTotals.shipping.toLocaleString("USD", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </div>
                  </div>
                  <div className="w-layout-hflex purchase-summary-total">
                    <div className="eyebrow s">Total</div>
                    <div className="eyebrow s">
                      {(
                        cartTotals.total +
                        cartTotals.tax +
                        cartTotals.shipping
                      ).toLocaleString("USD", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </div>
                  </div>
                </div>
                {/* <div className="purchase-summary-shipping-info">
                  <div className="header-xs">Shipping Information</div>
                  <p className="caption-s">
                    {purchaseData.shipping.firstName +
                      " " +
                      purchaseData.shipping.lastName}{" "}
                    <br />
                    {purchaseData.shipping.address} <br />
                    {purchaseData.shipping.city +
                      ", " +
                      purchaseData.shipping.state}
                    <br />
                    {purchaseData.shipping.zipCode}
                  </p>
                </div>
                <div className="purchase-summary-shipping-info">
                  <div className="header-xs">Payment Method</div>
                  <p className="caption-s">
                    {purchaseData.payment.firstName}{" "}
                    {purchaseData.payment.lastName}
                    <br />
                    {purchaseData.payment.number}
                    <br />
                    {purchaseData.payment.expiration}
                  </p>
                  {warning != null ? (
                    <p
                      style={{
                        fontSize: 20,
                        color: "red",
                        textAlign: "center",
                      }}
                    >
                      Your card was declined
                    </p>
                  ) : null}
                 
                </div> */}
                <button
                  className="button-dense-primary-light"
                  type="button"
                  onClick={() => navigate("/shop")}
                >
                  Continue Shopping
                </button>
                <button className="button-dense-primary-dark" type="submit">
                  Purchase
                </button>
              </div>
            </div>
          </div>
        </section>
      </form>
      <Footer />
    </>
  );
}
