import Calendar from "react-calendar";
import { useState, useEffect } from "react";
import Navbar from "../components/navigations";
import axios from "axios";
import moment from "moment";
import Footer from "../components/footer";
import { width } from "@mui/system";
import PrivateEvent, { PublicEvent } from "../components/calendar-events";
import { GetEvents } from "../services/events";

export default function EventCalendar() {
  const [eventsList, setEventsList] = useState([]);
  const [value, onChange] = useState(new Date());
  const [filteredList, setFilteredList] = useState([]);

  async function getEventsList() {
    const events = await GetEvents();
    setEventsList(events);
  }

  useEffect(() => {
    getEventsList();
  }, []);

  async function matchEventDates(date) {
    const newList = eventsList.filter((event) => {
      if (moment(event.fields.eventStartDate).format("YYYY-MM-DD") == date) {
        return event;
      }
    });
    setFilteredList(newList);
  }

  function dotContainer(calendarInfo) {
    return (
      <div className="dotContainer">
        {" "}
        {eventsList.map((event, index) => {
          if (
            moment(event.fields.eventStartDate).format("YYYY-MM-DD") ==
            moment(calendarInfo.date).format("YYYY-MM-DD")
          ) {
            if (event.fields.isPrivateEvent) {
              return (
                <div
                  className="privateDot"
                  style={{ backgroundColor: "green", width: 10, height: 10 }}
                  key={index}
                ></div>
              );
            } else {
              return (
                <div
                  className="publicDot"
                  style={{ backgroundColor: "red", width: 10, height: 10 }}
                  key={index}
                ></div>
              );
            }
          }
        })}
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <Navbar type="dark" />
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs light title-underline">Events</div>
          <div className="header-xxl pad-top-m">Join us at</div>
          <div className="header-xxl light pad-top-xs">
            Premier Aesthetic Events
          </div>
          <p className="body-l pad-top-m">
            Experience hands-on training, expert insights, and exclusive
            networking opportunities that will help you stay ahead in the field
            of aesthetic medicine.
          </p>
        </div>
      </section>
      <section className="white-background-section">
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
          }}
        >
          <div className="calendar-container">
            <Calendar
              onChange={onChange}
              value={value}
              className="calendar"
              goToRangeStartOnSelect={false}
              view="month"
              style={{ width: "100%", height: 300 }}
              tileClassName={({ activeStartDate, date, view }) => {
                if (
                  moment(value).format("YYYY-MM-DD") ==
                  moment(date).format("YYYY-MM-DD")
                ) {
                  return "calendarTileSelected";
                } else {
                  return "calendarTile";
                }
              }}
              tileContent={(calendarInfo) => {
                return dotContainer(calendarInfo);
              }}
              onClickDay={(value, event) => {
                matchEventDates(moment(value).format("YYYY-MM-DD"));
                onChange(value);
              }}
            />
          </div>
          <div className="day-details-container">
            <h1 className="heading-5">
              {moment(value).format("dddd MMMM Do, YYYY")}
            </h1>
            {filteredList.map((event, index) => {
              return event.fields.isPrivateEvent ? (
                <PrivateEvent event={event} key={index} />
              ) : (
                <PublicEvent event={event} key={index} />
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
