export default function AgendaLineItem({ item }) {
  return (
    <>
      <style>
        {`.box-wrapper {
            -webkit-transition-duration: 600ms;
            transition-duration: 600ms;
        }
        
        .box-wrapper.loading {
            transform: translate(100%);
            -webkit-transform: translate(100%);    
        }
        `}
      </style>
      <div className="agenda-line-item box-wrapper loading light">
        <div className="div-block-38">
          <div className="body-m">{item.time}</div>
          {/* <div className="body-m">9:00am</div> */}
        </div>
        <div className="header-s light" style={{ color: "white" }}>
          {item.title}
        </div>
        <div className="body-m">{item.description}</div>
      </div>
      {/* <div className="agenda-line-item box-wrapper loading">
        <div className="agenda-line-item__time">{item.time}</div>
        <div className="agenda-line-item__title">{item.title}</div>
        <div className="agenda-line-item__description">{item.description}</div>
      </div> */}
    </>
  );
}
