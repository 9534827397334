import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTrainingContext } from "../context/clinicalTrainingContext";
import AgendaLineItem from "../components/agenda-line-item";
import TestimonialsSection from "../components/testimonials-section";
import Footer from "../components/footer";
import TeamMemberCard from "../components/teamCard";
import Navbar from "../components/navigations";

export default function Labs() {
  const { search } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const [labDetails, setLabDetails] = useState({
    bulletPoints: [],
    agenda: [],
    staff: [],
    vendors: [],
    description: "",
    packages: [],
  });
  const [agenda, setAgenda] = useState([]);

  const searchCode = new URLSearchParams(search).get("referralCode");
  const affiliateParam = new URLSearchParams(search).get("affiliate");

  const { addCoupons } = useTrainingContext();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const urlParams = new URLSearchParams({
    search,
    title: labDetails.title,
    date: labDetails.date,
  });

  const [modalInfo, setModalInfo] = useState({
    name: "Dr. Alan Durkin, MD",
    title: "Board Certified Plastic Surgeon",
    image: "Durkin_Square_1000x1000.webp",
    bio: [""],
  });

  const [userDetails, setUserDetails] = useState({
    referralCode: searchCode ? searchCode : "",
  });

  async function getInitalData() {
    axios
      .get(
        "/api/v2/website/events/get-lab-details?eventName=" +
          location.pathname.split("/")[2]
      )
      .then((res) => {
        setLabDetails(res.data.data);
        setAgenda(res.data.data.agenda.day1.agenda);
        addCoupons(res.data.data.discountCodes);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      setUserDetails({ ...userDetails, ip: res.data.ip });
    });

    getInitalData();

    if (localStorage.getItem("authToken")) {
      setIsLoggedIn(true);
    }
  }, []);

  function changeAgenda(day) {
    const elms = document.getElementsByClassName("box-wrapper");

    for (let i = 0; i < elms.length; i++) {
      elms[i].classList.add("loading");
    }

    if (day == 1) {
      document.getElementById("day1").classList.add("selected");
      document.getElementById("day2").classList.remove("selected");
      document.getElementById("day1").classList.remove("unselected");
      document.getElementById("day2").classList.add("unselected");
    } else {
      document.getElementById("day2").classList.add("selected");
      document.getElementById("day1").classList.remove("selected");
      document.getElementById("day2").classList.remove("unselected");
      document.getElementById("day1").classList.add("unselected");
    }
    setAgenda(labDetails.agenda[`day${day}`].agenda);
  }

  useEffect(() => {
    const elms = document.getElementsByClassName("box-wrapper");

    for (let i = 0; i < elms.length; i++) {
      setTimeout(() => {
        elms[i].classList.remove("loading");
      }, 100 * i);
    }
  }, [agenda]);

  console.log(labDetails);

  return (
    <div>
      <Navbar type={"dark"} />
      <div
        data-poster-url="../assets/videos/Website-Hero-Video-BW-poster-00001.jpg"
        data-video-urls="../assets/videos/Website-Hero-Video-BW-transcode.mp4,../assets/videos/Website-Hero-Video-BW-transcode.webm"
        data-autoplay="true"
        data-loop="true"
        data-wf-ignore="true"
        className="background-video-masterclass w-background-video "
        style={{ minHeight: "75vh", height: "auto" }}
      >
        <video
          id="b8440dfd-c60b-24b0-c557-d4aaa325d202-video"
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          data-wf-ignore="true"
          data-object-fit="cover"
          style={{ minHeight: "75vh", height: "auto" }}
        >
          <source
            src={require("../assets/videos/Website-Hero-Video-BW-transcode.mp4")}
            data-wf-ignore="true"
          />
          <source
            src={require("../assets/videos/Website-Hero-Video-BW-transcode.webm")}
            data-wf-ignore="true"
          />
        </video>
        <div className="w-layout-blockcontainer container vertical-nav">
          <div className="breadcrumb-nav"></div>
          <div className="masterclass-title">
            <div className="header-xxl regularThin">
              MedSpa Network Advanced Masterclass Event
            </div>
            <div
              className="body-l light center"
              style={{ color: "white", fontWeight: 300 }}
            >
              The MedSpa Network Event is a premier training experience offering
              a unique opportunity to expand your expertise in advanced
              aesthetic procedures through hands-on training and live
              demonstrations.This event includes a Cadaver Lab, a Live Face Lift
              demonstration, and sessions on Rejuvenation Techniques, blending
              surgical precision with non-surgical advancements. Led by renowned
              industry experts, including Dr. Alan Durkin, this event is
              tailored for professionals eager to refine their craft, enhance
              patient outcomes, and grow their practice
            </div>
          </div>
          <a
            text="Register Today"
            href="#packages"
            className="button-dense-primary-light "
          >
            Register Today
          </a>
        </div>
      </div>
      <section
        className="blue-background-section"
        style={{ paddingTop: 8, paddingBottom: 48 }}
      >
        <div className="w-layout-blockcontainer container ">
          <div
            id="w-node-baf977d2-a00a-51e6-8143-d91326088cae-6df1ccd1"
            className="w-layout-layout full-width-stack pad-top-xl wf-layout-layout"
          >
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088caf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img
                src={require("../assets/icons/calendar-2.png")}
                style={{ width: 50, height: 50, objectFit: "contain" }}
              />
              <div>
                <div className="header-m">Lab Dates</div>
                <p className="body-s light">February 21-22, 2025</p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cb7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img
                src={require("../assets/icons/location-pin-1.png")}
                style={{ width: 50, height: 50, objectFit: "contain" }}
              />
              <div>
                <div className="header-m">Ocean Drive Plastic Surgery</div>
                <p className="body-s light">
                  5070 Hwy A1A Ste. A, Vero Beach FL, 329
                </p>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cbf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img
                src={require("../assets/icons/low-cost.png")}
                style={{ width: 50, height: 50, objectFit: "contain" }}
              />

              <div>
                <div className="header-m">Full 2 Day Training</div>
                <p className="body-s light">$1,950</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="light-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="vertical">
            <h2 className="header-xl">Watch the Trailer</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                gap: 32,
              }}
            >
              <div
                style={{
                  gap: 12,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p className="body-l">
                  Gain valuable insights from “over-the-shoulder” training
                  sessions with renowned experts like Dr. Alan Durkin, MD, who
                  will guide you through real-time injections and provide
                  in-depth educational support to enhance your skills.
                </p>
                <p className="body-l">
                  Engage in small group sessions designed to maximize
                  personalized learning, as you rotate through specialized focus
                  areas led by industry experts, with content tailored to your
                  level of experience.
                </p>
                <a
                  text="Register Today"
                  href="#packages"
                  className="button-dense-primary-dark "
                  style={{ backgroundColor: "#0a0d4b" }}
                >
                  Register Today
                </a>
              </div>
              <div className="div-block-22">
                <div
                  style={{ paddingTop: "50%" }}
                  className="w-embed-youtubevideo youtube"
                >
                  <iframe
                    src="https://www.youtube.com/embed/X2rGgsqaq3A?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                    frameBorder="0"
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                      pointerEvents: "auto",
                    }}
                    allow="autoplay; encrypted-media"
                    allowFullScreen=""
                    title="Medspa Network training December 2023"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify">
          <h2 className="header-xl">Key Takeaways</h2>
          <p className="body-l light">
            This event is a must-attend for professionals seeking to elevate
            their expertise and deliver exceptional patient outcomes in both
            surgical and non-surgical aesthetics.
          </p>
          <a
            text="Register Today"
            href="#packages"
            className="button-dense-primary-light "
          >
            Register Today
          </a>
          <div
            id="w-node-baf977d2-a00a-51e6-8143-d91326088cae-6df1ccd1"
            className="w-layout-layout full-width-stack pad-top-xl wf-layout-layout"
          >
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088caf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/head.png")} />
              <div
                style={{
                  flexDirection: "column",
                  gap: 8,
                  display: "flex",
                }}
              >
                <div className="header-m">Cadaver Lab Mastery</div>
                <ul style={{ paddingLeft: 15 }}>
                  <li className="body-s light">
                    Gain in-depth insights into facial anatomy for precision in
                    injectable treatments.
                  </li>
                  <li className="body-s light">
                    Practice advanced techniques to minimize risks and enhance
                    outcomes.
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cb7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/facelift.png")} />
              <div
                style={{
                  flexDirection: "column",
                  gap: 8,
                  display: "flex",
                }}
              >
                <div className="header-m">Live Face Lift Demonstration</div>
                <ul style={{ paddingLeft: 15 }}>
                  <li className="body-s light">
                    Observe Dr. Durkin perform a live surgical face lift,
                    showcasing innovative techniques and artistry
                  </li>
                  <li className="body-s light">
                    Learn how surgical procedures complement nonsurgical
                    treatments for holistic patient care.
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cbf-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/syringe-outline (1).png")} />

              <div
                style={{
                  flexDirection: "column",
                  gap: 8,
                  display: "flex",
                }}
              >
                <div className="header-m">Rejuvenation Techniques</div>
                <ul style={{ paddingLeft: 15 }}>
                  <li className="body-s light">
                    Explore the latest non-surgical rejuvenation methods,
                    including advanced injectables, thread lifts, and skin
                    revitalization
                  </li>
                  <li className="body-s light">
                    Understand the science behind combination therapies to
                    achieve natural, balanced results.
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cc7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/visionary.png")} />

              <div
                style={{
                  flexDirection: "column",
                  gap: 8,
                  display: "flex",
                }}
              >
                <div className="header-m">Business Insights</div>
                <ul style={{ paddingLeft: 15 }}>
                  <li className="body-s light">
                    Discover strategies for integrating new techniques into your
                    practice to attract and retain patients.
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="w-node-baf977d2-a00a-51e6-8143-d91326088cc7-6df1ccd1"
              className="w-layout-cell stat-item"
            >
              <img src={require("../assets/icons/management.png")} />

              <div
                style={{
                  flexDirection: "column",
                  gap: 8,
                  display: "flex",
                }}
              >
                <div className="header-m">Networking Opportunities</div>
                <ul style={{ paddingLeft: 15 }}>
                  <li className="body-s light">
                    Connect with peers and industry leaders to share insights
                    and foster professional growth.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="speakers" className="white-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="vertical">
            <div className="paragraph-section-right-pad">
              <h5 className="header-xs ">Meet the team</h5>
              <div className="paragraph-section-paragraph-copy">
                <h2 className="header-xl ">Speakers</h2>
                <p className="body-l ">
                  Our team comprises experienced medical and business
                  professionals who prioritize education, training, and patient
                  care. Our medical practitioners are board-certified experts in
                  medical aesthetics, and our business professionals excel at
                  running client-focused medspas.
                </p>
              </div>
            </div>
            <div
              className="staff-card-container"
              style={{ overflow: "scroll" }}
            >
              {labDetails.staff.map((staff, index) => {
                return (
                  <TeamMemberCard
                    key={index}
                    name={staff.name}
                    credential={staff.title}
                    image={"/content/staff/" + staff.image}
                    socials={{
                      linkedin: "#",
                      instagram: "#",
                    }}
                    bio={staff.bio}
                  />
                );
                {
                  /* return <MasterclassStaffCard staff={staff} key={index} />; */
                }
              })}
            </div>
          </div>
        </div>
      </section>
      <section id="agenda" className="blue-background-section">
        <div className="w-layout-blockcontainer container ">
          <div className="vertical gap20">
            <h2 className="left-adjusted-heading">
              Event Schedule &amp; Agenda
            </h2>
            <div className="tag-bar">
              <button
                className="tag rounded transparent selected"
                id="day1"
                onClick={() => changeAgenda(1)}
              >
                <p className="paragraph-7">Fri FEB 21</p>
              </button>
              <button
                className="tag rounded transparent unselected"
                id="day2"
                onClick={() => changeAgenda(2)}
              >
                <p className="paragraph-7">SAT FEB 22</p>
              </button>
            </div>
            <div className="agenda-table">
              {agenda.map((item, index) => {
                {
                  return (
                    <AgendaLineItem item={item} key={index} index={index} />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>
      <section id="packages" className="white-background-section">
        <div className="w-layout-blockcontainer container">
          <div className="vertical center">
            <h2 className="header-l" style={{ fontWeight: 300 }}>
              Select Your Training Package
            </h2>
            <h2 className="header-l light" style={{ fontWeight: 300 }}>
              That Best Suits Your Professional Goals and Expertise.
            </h2>
            <div
              className="pricing-card-container"
              style={{
                justifyContent: "center",
                alighItems: "center",
                width: "100%",
              }}
            >
              {labDetails.packages.map((packageDetails, index) => {
                return (
                  <div
                    className="pricing-card"
                    key={index}
                    style={{ maxWidth: 400 }}
                  >
                    <div className="pricing-card-top">
                      <div
                        className="pricing-card-text"
                        style={{ color: "black" }}
                      >
                        2-Day
                      </div>
                      <div
                        className="pricing-card-text"
                        style={{ color: "black" }}
                      >
                        {packageDetails.title}
                      </div>
                      <div
                        className="pricing-card-price"
                        style={{ color: "black" }}
                      >
                        {affiliateParam?.toLowerCase() == "larkin"
                          ? Number(550).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                            })
                          : packageDetails.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 0,
                            })}
                      </div>

                      <div className="badge">
                        <img
                          src={require("../assets/icons/calendar-2.png")}
                          loading="lazy"
                          alt=""
                          className="tag-icon"
                        />
                        <div className="tag-text" style={{ color: "black" }}>
                          Feb 21 2025
                        </div>
                      </div>
                    </div>
                    <div className="pricing-card-bottom">
                      <div>Includes</div>
                      {packageDetails.details.map((detail, index) => {
                        return (
                          <div
                            className="pricing-card-bottom-bullet-container"
                            key={index}
                          >
                            <img
                              src={require("../assets/icons/next-1.png")}
                              loading="lazy"
                              alt=""
                              className="pricing-card-bullet-icon"
                            />
                            <div className="body-s">{detail}</div>
                          </div>
                        );
                      })}
                    </div>
                    <Link
                      to={`/masterclass-checkout?affiliate=${affiliateParam}&${urlParams}&price=${packageDetails.price}`}
                      className="button-dense-primary-dark margin-top-20 "
                      style={{ maxWidth: 250 }}
                    >
                      Select &amp; Register
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section id="venue" className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container ">
          <h2 className="header-l">Event Location</h2>
          <div className="horizontal">
            <div className="masterclass-location-details-container">
              <div className="div-block-27">
                <div className="code-embed-2 w-embed w-iframe">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.3268133093743!2d-80.3665424238134!3d27.676292276200417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88de595360e69cf7%3A0x95491b5daf331fed!2sDr.%20Alan%20Durkin%20MD%20at%20Ocean%20Drive%20Plastic%20Surgery!5e0!3m2!1sen!2sus!4v1727195743937!5m2!1sen!2sus"
                    width="100%"
                    height="270"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="div-block-26">
                <div>
                  <h3 className="header-s">Training Location</h3>
                  <p className="body-s">
                    Ocean Drive Plastic Surgery
                    <br />
                    5070 Hwy A1A, Ste. A<br />
                    Vero Beach, FL, 32963
                  </p>
                </div>
                <a href="#" className="white-fill-button ">
                  Open in Maps
                </a>
              </div>
            </div>
            <div className="masterclass-location-details-container">
              <div className="div-block-27">
                <div className="code-embed-2 w-embed w-iframe">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10861.960640826448!2d-80.36630989987971!3d27.662251374198192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88de5930b0407c9f%3A0x800731989fde47d6!2sCosta%20d&#39;Este%20Beach%20Resort%20%26%20Spa!5e0!3m2!1sen!2sus!4v1727812489502!5m2!1sen!2sus"
                    width="100%"
                    height="270"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="div-block-26">
                <div>
                  <h3 className="header-s">Mixer Location</h3>
                  <p className="body-s">
                    Costa De Este
                    <br />
                    5070 Hwy A1A, Ste. A<br />
                    Vero Beach, FL, 32963
                  </p>
                </div>
                <a href="#" className="white-fill-button ">
                  Open in Maps
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </div>
  );
}
